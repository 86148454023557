import {Button, Checkbox, Col, DatePicker, Form, Input, List, Row, Spin, Typography,} from "antd";
import {useDispatch, useSelector} from "react-redux";
// import AddRelativeProfileDetails from "../profile/index";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {getAddressObject} from "../../../common/helper";
import {useEffect, useState} from "react";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import axios from "axios";
import {setAlert} from "../../../redux/alert/alert.action";
import Sidebar from "../../../components/sidebar";
import AppointmentNavBar from "../appointment/appointmentsNavBar";
import TextArea from "antd/lib/input/TextArea";
import CheckBoxComponent from "../../../components/forms/check-box";
import RadioBox from "../../../components/forms/v-radio-box";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import "./relative.less";
import {DeleteConfirmationModal} from "./relativesList";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import ScrollWrapper from "../../../components/ScrollWrapper/ScrollWrapper";
import Footer from "../../../components/Footer/Footer";
import {toggleChooseRelativeModal, toggleConfirmRelativeModal,} from "../../../redux/modals/toggleModal.actions";

const {Title} = Typography;
export default function AddNewRelative(props) {
    const {state} = useLocation();
    console.log(state, "relativedata");

    const relativeData = state?.relativeData;
    const token = useSelector((state) => state.loginData.user.accessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const {
        placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading,
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE, options: {
            componentRestrictions: {country: "IN"},
        },
    });

    const [profilePic, setProfilePic] = useState(relativeData?.image ?? "");
    const [disabledSave, setDisabledSave] = useState(true);
    const [contryCode, setCountryCode] = useState(relativeData?.countryCode);
    const [mobile, setMobile] = useState(relativeData?.phone);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [value, setValue] = useState(relativeData?.address);
    const [showList, SetShowList] = useState(false);
    const [diseaseData, setDiesaseData] = useState([]);
    const [loadDiesese, setLoadDiesese] = useState(false);
    const [geocode, setGeocode] = useState(relativeData?.location);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const routerData = useLocation();
    const IS_APPOINTMENT = routerData.state?.IS_APPOINTMENT;
    const [hasDisease, setHasDisease] = useState(props.ADDING_NEW ? [] : [...relativeData?.disease]);
    const logindata = useSelector((state) => state.loginData);
    const [hasPets, setHasPets] = useState([]);
    const [saveloading, setSaveLoading] = useState(false);
    const [deleteConfirmationVisible, setDeleteConfirmation] = useState(false);

    const param = useParams();
    useEffect(() => {
        if (relativeData?.firstName) {
            dispatch(ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.relatives.concat({
                title: relativeData?.firstName, route: `relatives/view-relative/${param.id}`, state: {
                    relativeData: relativeData,
                },
            }, {
                title: "Edit", route: ``, state: {
                    relativeData: relativeData,
                },
            })));
        } else {
            dispatch(ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.relatives.concat({
                title: "Add", route: ``,
            })));
        }
    }, [relativeData?.firstName]);

    useEffect(() => {
        fetchDieseseData();
        if (!props.ADDING_NEW) {
            const formData = {...relativeData};
            form.setFieldsValue({...formData});
            form.setFieldValue("dob", moment(formData?.dob, "DD/MM/YYYY"));
            form.setFieldValue("phone_no", formData?.countryCode + formData?.phone);
            if (formData?.otherDisease) {
                setHasDisease((prev) => [...prev, "0"]);
                setIsOther(true);
            }
            if (formData?.pets.includes("PetFree")) {
                setHasPets(["Pet Free"]);
                form.setFieldValue("pets", ["Pet Free"]);
            } else {
                setHasPets(formData.pets);
                form.setFieldValue("pets", formData.pets);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log(relativeData, "rere");

    // useEffect(() => {
    //   dispatch(
    //     ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.Appointments.concat( {
    //       title:'My Relatives',
    //       route: `appointment-details`,
    //     },))
    //   );

    // }, []);

    const fetchDieseseData = () => {
        setLoadDiesese(true);
        axios
            .post(`${process.env.REACT_APP_BASE_URL}/disease/list`, {}, {
                headers: {
                    accessToken: token,
                    Authorization: "Basic " + btoa(process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD),
                },
            })
            .then((response) => {
                console.log("response", response);
                if (response.data.status === 200) {
                    const data = [...response.data.output, {name: "Other", _id: "0"}];
                    setDiesaseData(data);
                    setLoadDiesese(false);
                } else if (response.data.status >= 400) {
                    console.log(response);
                    setLoadDiesese(false);
                } else {
                    setLoadDiesese(false);
                }
            })
            .catch((err) => {
                setLoadDiesese(false);
                if (err?.response.data.status >= 400) {
                    dispatch(setAlert({
                        alertType: "error", alertMsg: err?.response?.data?.error?.message, alertOpen: true,
                    }));
                    if (err.response.data.status === 401) {
                        localStorage.clear();
                        navigate("/");
                        window.location.reload();
                    }
                } else {
                    if (err?.response?.data?.status >= 400 && err?.response?.data?.status !== 401) {
                        dispatch(setAlert({
                            alertType: "error", alertMsg: err?.response?.data?.error?.message, alertOpen: true,
                        }));
                    }
                }
            });
    };

    const genders = [{
        icon: (<img
                className="me-3"
                width={25}
                src="/images/icons/male.svg"
                alt="male icon"
            />), name: "Male", value: 1,
    }, {
        icon: (<img
                className="me-3"
                width={25}
                src="/images/icons/female.svg"
                alt="female icon"
            />), name: "Female", value: 2,
    }, {
        icon: (<img
                className="me-3"
                width={25}
                src="/images/icons/others.svg"
                alt="others icon"
            />), name: "No preference", value: 3,
    },];

    const getDetails = (place_id) => {
        SetShowList(false);
        placesService?.getDetails({
            placeId: place_id,
        }, (placeDetails) => {
            console.log("placeDetails", placeDetails, placeDetails.geometry.location.lat(), placeDetails.geometry.location.lng());
            const address = getAddressObject(placeDetails?.address_components);
            setValue(address?.home + " " + address?.street);
            console.log(address?.home + address?.street);
            form.setFieldValue("address", address?.home + address?.street);
            form.setFieldValue("city", address?.city);
            form.setFieldValue("province", address?.region);
            form.setFieldValue("postalCode", address?.postal_code);
            form.validateFields(["postalCode"]);
            const location = {
                lat: `${placeDetails.geometry.location.lat()}`, lon: `${placeDetails.geometry.location.lng()}`,
            };
            setGeocode(location);
            //    handleFormChange()
        });
    };

    const handleImageUpload = (e) => {
        const [file] = e.target.files;
        if (file) {
            setLoadingProfile(true);
            let formData = new FormData();
            formData.append("image", e.target.files[0]);
            formData.append("path", "test");
            console.log("formData", formData);
            if (token !== "") {
                axios
                    .post(`${process.env.REACT_APP_BASE_URL}/user/upload-image`, formData, {
                        headers: {
                            accessToken: token,
                            Authorization: "Basic " + btoa(process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD),
                        },
                    })
                    .then((response) => {
                        console.log("response", response);
                        // console.log("response", response)
                        if (response.data.status === 200) {
                            setProfilePic(response.data.output.imageUrl1.location);
                            setLoadingProfile(false);
                        } else if (response.data.status >= 400) {
                            setLoadingProfile(false);
                            dispatch(setAlert({
                                alertType: "error", alertMsg: response?.data?.error?.message, alertOpen: true,
                            }));
                        } else {
                            setLoadingProfile(false);
                            dispatch(setAlert({
                                alertType: "error",
                                alertMsg: "something went wrong! please try again.",
                                alertOpen: true,
                            }));
                        }
                    })
                    .catch((err) => {
                        setLoadingProfile(false);
                        if (err?.response.data.status >= 400) {
                            dispatch(setAlert({
                                alertType: "error", alertMsg: err?.response?.data?.error?.message, alertOpen: true,
                            }));
                            if (err.response.data.status === 401) {
                                localStorage.clear();
                                navigate("/");
                                window.location.reload();
                            }
                        } else {
                            dispatch(setAlert({
                                alertType: "error",
                                alertMsg: "something went wrong! please try again.",
                                alertOpen: true,
                            }));
                        }
                    });
            }
        }
    };

    const handleSaveData = (values) => {
        const newValues = {
            ...values,
            image: profilePic,
            location: geocode,
            dob: moment(values?.dob).format("DD/MM/YYYY"),
            phone: mobile,
            countryCode: contryCode,
            disease: values.disease?.filter((i) => i != 0),
        };
        if (!props.ADDING_NEW) {
            newValues["id"] = relativeData?._id;
        }
        setSaveLoading(true);
        axios
            .post(`${process.env.REACT_APP_BASE_URL}/user/${!props.ADDING_NEW ? "edit-relatives" : "add-relatives"}`, newValues, {
                headers: {
                    accessToken: token,
                    Authorization: "Basic " + btoa(process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD),
                },
            })
            .then((response) => {
                setSaveLoading(false);
                if (response?.data?.status === 200) {
                    dispatch(setAlert({
                        alertType: "success", alertMsg: response?.data?.message, alertOpen: true,
                    }));
                    if (relativeData?.REDIRECT_FROM_APPOINTMENTS) {
                        navigate("/client/appointment/create-appointment/dates");
                        localStorage.setItem("relative", JSON.stringify({...relativeData, ...newValues}));
                        dispatch(toggleConfirmRelativeModal(true));
                    } else if (IS_APPOINTMENT) {
                        if (!logindata?.homeData?.isFirstAppointmentCreated) {
                            navigate("/client/appointment");
                        } else {
                            navigate("/client/appointments-dashboard");
                        }
                        localStorage.setItem("relative", JSON.stringify({...relativeData, ...newValues}));
                        dispatch(toggleChooseRelativeModal(true));
                    } else {
                        navigate("/client/relatives");
                    }
                } else if (response?.data?.status >= 400) {
                    dispatch(setAlert({
                        alertType: "error", alertMsg: response?.data?.error?.message, alertOpen: true,
                    }));
                }
            })
            .catch((err) => {
                setSaveLoading(false);
                if (err?.response?.data?.status >= 400) {
                    dispatch(setAlert({
                        alertType: "error", alertMsg: err?.response?.data?.error?.message, alertOpen: true,
                    }));
                    if (err.response?.data?.status === 401) {
                        localStorage.clear();
                        navigate("/");
                        window.location.reload();
                    }
                }
            });
    };
    console.log(contryCode, "contryCode");
    const handleFormChange = (changedFields) => {
        if (changedFields[0]?.name[0]?.includes("postalCode")) {
            let val = changedFields[0]?.value;
            val = val.replace(/[^\w\s]/g, "");
            if (val[3] && val[3] != " ") {
                val = val?.replace(/(.{3})/, "$1 ");
            }
            form.setFieldValue("postalCode", val.toUpperCase());
        }
        if (changedFields?.length > 0 && (changedFields[0]?.name[0]?.includes("firstName") || changedFields[0]?.name[0]?.includes("lastName"))) {
            const name = changedFields[0]?.name[0];
            let val = changedFields[0]?.value;
            val = val.charAt(0).toUpperCase() + val.slice(1);
            form.setFieldValue(name, val);
        }
    };

    return (<>
            {/*      {isMobile ? (*/}
            {/*        <div style={{ background: "#E4E4E4", minHeight: "100vH" }}>*/}
            {/*        <div className='container-fluid relative-list-container'>*/}
            {/*        <div className='p-2 pt-3'><Responsiveheader title="Appointments" /></div>*/}
            {/*            <div className='d-flex'> */}
            {/*         <div className="relative-form-wrapper ms-2" style={{background: "#fff", padding: "40px 20px 10px 20px", borderRadius: "25px", height: "90vh", overflow: "auto"}}>*/}
            {/*    /!* <h1 className='color-primary fw-bolder mt-4'>Relative's Details</h1> *!/*/}
            {/*    <div className="px-md-5 text-center position-relative">*/}
            {/*      <Spin spinning={loadingProfile} tip="loading..." >*/}
            {/*        <div className='user-circle mx-auto'>*/}
            {/*          {console.log("profilePic", profilePic)}*/}
            {/*        {!profilePic ? <img style={{width:"100%",height:"100%", objectFit:"scale-down"}} src="/images/icons/Profile.png" alt="dummy profile icon" /> : */}
            {/*        <img style={{width:"100%",height:"100%", objectFit:"scale-down", borderRadius:"50%"}} src={profilePic} alt="dummy profile icon" />}*/}
            {/*          <label type="file" className="upload-file pointer" style={{position:'absolute', bottom:"0px", right:'0px'}}>*/}
            {/*          <input for="file" className="btn-3" onChange={handleImageUpload} accept="image/*" type="file" />*/}
            {/*          </label>*/}
            {/*        </div>              */}
            {/*      </Spin>*/}
            {/*    </div>*/}

            {/*    <Form*/}
            {/*            name="signup-form"*/}
            {/*            onFinish={handleSaveData}*/}
            {/*            autoComplete="off"*/}
            {/*            layout="vertical"*/}
            {/*            // validateTrigger='onSubmit'*/}
            {/*            // onFieldsChange={handleFormChange}*/}
            {/*            form={form}*/}
            {/*            requiredMark={false}*/}
            {/*            // initialValues={initialData}*/}
            {/*            className='mb-0 p-md-4 text-start relative-form'*/}
            {/*          >  */}
            {/*          <Row gutter={[16, 0]} style={{ marginTop: '1rem' }}>*/}
            {/*          <Col xs={24} md={12} lg={8}>*/}
            {/*            <Form.Item*/}
            {/*                name="firstName"*/}
            {/*                type="text"*/}
            {/*                className="mb-3"*/}
            {/*                label="First Name"*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                  required: true,*/}
            {/*                  message: 'Please enter your first name',*/}
            {/*                },*/}
            {/*                {*/}
            {/*                  pattern: new RegExp(/^[A-Za-z]+$/),*/}
            {/*                  message: "Field does not accept numbers or special characters."*/}
            {/*                 }*/}
            {/*                ]}*/}
            {/*              >*/}
            {/*                <Input size="large" placeholder="First Name"  />*/}
            {/*              </Form.Item>*/}
            {/*            </Col>*/}
            {/*           <Col xs={24} md={12} lg={8} style={{ marginTop: '1rem' }}>*/}
            {/*            <Form.Item*/}
            {/*                name="lastName"*/}
            {/*                type="text"*/}
            {/*                label="Last Name"*/}
            {/*                className="mb-3"*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                  required: true,*/}
            {/*                  message: 'Please enter your last name',*/}
            {/*                },*/}
            {/*                {*/}
            {/*                  pattern: new RegExp(/^[A-Za-z]+$/),*/}
            {/*                  message: "Field does not accept numbers or special characters."*/}
            {/*                 }]}*/}
            {/*              >*/}
            {/*                <Input size="large" placeholder="Last Name"  />*/}
            {/*              </Form.Item>*/}
            {/*              */}
            {/*            </Col>*/}
            {/*            <Col xs={24} md={12} lg={8} style={{ marginTop: '1rem' }}>*/}
            {/*            <Form.Item*/}
            {/*                name="dob"*/}
            {/*                type="text"*/}
            {/*                label="Date of Birth"*/}
            {/*                className="mb-3"*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                  required: true,*/}
            {/*                  message: 'Please enter your date of birth!',*/}
            {/*                }]}*/}
            {/*              >*/}
            {/*                    <DatePicker style={{ width: '100%', height: "60px" }}*/}
            {/*                    clearIcon={null}*/}
            {/*                      suffixIcon={<img src="/images/icons/calendar.svg" alt="calendar" />}*/}
            {/*                      placeholder="Date of Birth"*/}
            {/*                      format={"DD/MM/YYYY"}*/}
            {/*                      disabledDate={current => {*/}
            {/*                      return current && current > moment().subtract(216, "month");*/}
            {/*                      }}*/}
            {/*                      onChange={(d) => form.setFieldValue('dob', d)}*/}
            {/*                      size='large' />*/}
            {/*              </Form.Item>*/}
            {/*              */}
            {/*            </Col>*/}
            {/*            <Col xs={24} md={12} lg={8} style={{ marginTop: '1rem' }}>*/}
            {/*            <Form.Item*/}
            {/*                name="phone_no"*/}
            {/*                type="text"*/}
            {/*                label="Phone No."*/}
            {/*                className="mb-3"*/}
            {/*                // rules={[*/}
            {/*                // {*/}
            {/*                //   required: true,*/}
            {/*                //   message: 'Please enter your phone number!',*/}
            {/*                // }]}*/}
            {/*              >*/}
            {/*                /!* {console.log("editRelData?.countryCode", editRelData?.countryCode)} *!/*/}
            {/*              <PhoneInput*/}
            {/*                inputExtraProps={{*/}
            {/*                placeholder:'Enter phone number',*/}
            {/*                searchPlaceholder:"Enter phone number"*/}
            {/*              }}*/}
            {/*                placeholder='Enter phone number'*/}
            {/*                searchPlaceholder="Enter phone number"*/}
            {/*                country={relativeData?.countryCode === undefined ? 'ca' : relativeData?.countryCode}*/}
            {/*                value={(relativeData?.countryCode === undefined ? '' : relativeData?.countryCode) + relativeData?.phone}*/}
            {/*                enableAreaCodes={false}*/}
            {/*                copyNumbersOnly={false}*/}
            {/*                inputClass="ant-input ant-input-lg"*/}
            {/*                containerClass="ant-input-affix-wrapper"*/}
            {/*                dropdownClass="ant-select ant-select-single"*/}
            {/*                containerStyle={{padding:"2px 4px"}}*/}
            {/*                buttonStyle={{background:'none', border:'none'}}*/}
            {/*                inputStyle={{width:"100%", paddingLeft:"60px"}}*/}
            {/*                onChange={(value, country, e, formattedValue) => {*/}
            {/*                  form.setFieldValue('phone', value.slice(country?.dialCode?.length));*/}
            {/*                  if(value) {*/}
            {/*                  form.setFieldValue('countryCode', country?.dialCode);*/}
            {/*                  setCountryCode(country?.dialCode);*/}
            {/*                  }*/}
            {/*                  setMobile(value.slice(country?.dialCode?.length))*/}
            {/*                }}*/}

            {/*                />*/}
            {/*              </Form.Item>*/}
            {/*            </Col>*/}
            {/*            <Col xs={24} md={12} lg={8} style={{ marginTop: '1rem' }}>*/}
            {/*            <Form.Item*/}
            {/*                name="address"*/}
            {/*                type="text"*/}
            {/*                className="mb-3"*/}
            {/*                label="Address"*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                  required: true,*/}
            {/*                  message: 'Please enter your address!',*/}
            {/*                }]}*/}
            {/*              >*/}
            {/*                <Input */}
            {/*            size="large"*/}
            {/*            style={{ color: "black" }}*/}
            {/*            value={value}*/}
            {/*            onChange={(evt) => {*/}
            {/*              getPlacePredictions({ input: evt.target.value });*/}
            {/*              if(evt.target.value) {*/}
            {/*              SetShowList(true)*/}
            {/*              }*/}
            {/*              setValue(evt.target.value)*/}
            {/*            }}*/}
            {/*            onKeyDown={(event) => {*/}
            {/*              if(event?.keyCode === 8 || event?.keyCode === 46){*/}
            {/*                form.setFieldValue("city",'')*/}
            {/*                form.setFieldValue("province", '')*/}
            {/*                form.setFieldValue("postalCode", '')*/}
            {/*                // handleFormChange()*/}
            {/*              } */}
            {/*            }}*/}
            {/*            loading={isPlacePredictionsLoading} placeholder="Address"  />*/}
            {/*            {!isPlacePredictionsLoading && showList && (*/}
            {/*            <div className="google-autosuggestion-list">*/}
            {/*              <>*/}
            {/*              <List*/}
            {/*                dataSource={placePredictions}*/}
            {/*                renderItem={(item) => (*/}
            {/*                  <List.Item onClick={() => getDetails(item?.place_id)}>*/}
            {/*                  */}
            {/*                    <List.Item.Meta title={item.description} />*/}
            {/*                  </List.Item>*/}
            {/*                )}*/}
            {/*              />*/}
            {/*              </>*/}
            {/*          </div>*/}
            {/*            )}*/}
            {/*              </Form.Item>*/}
            {/*            </Col>*/}
            {/*            <Col xs={24} md={12} lg={8} style={{ marginTop: '1rem' }}>*/}
            {/*            <Form.Item*/}
            {/*                name="city"*/}
            {/*                type="text"*/}
            {/*                className="mb-3"*/}
            {/*                label="City"*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                  required: true,*/}
            {/*                  message: 'Please enter your city!',*/}
            {/*                }]}*/}
            {/*              >*/}
            {/*                <Input disabled size="large" placeholder="City"  />*/}
            {/*              </Form.Item>*/}
            {/*            </Col>*/}
            {/*            <Col xs={24} md={12} lg={8} style={{ marginTop: '1rem' }}>*/}
            {/*            <Form.Item*/}
            {/*                name="province"*/}
            {/*                type="text"*/}
            {/*                label="Province"*/}
            {/*                className="mb-3"*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                  required: true,*/}
            {/*                  message: 'Please enter your Province!',*/}
            {/*                }]}*/}
            {/*              >*/}
            {/*                <Input disabled size="large" placeholder="Province"  />*/}
            {/*              </Form.Item>*/}
            {/*            </Col>*/}
            {/*            <Col xs={24} md={12} lg={8} style={{ marginTop: '1rem' }}>*/}
            {/*            <Form.Item*/}
            {/*                name="postalCode"*/}
            {/*                type="text"*/}
            {/*                label="Postal Code"*/}
            {/*                className="mb-3"*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                  required: true,*/}
            {/*                  message: 'Please enter your Postal Code!',*/}
            {/*                }]}*/}
            {/*              >*/}
            {/*                <Input disabled size="large" placeholder="Postal Code"  />*/}
            {/*              </Form.Item>*/}
            {/*            </Col>*/}
            {/*            <Col xs={24} lg={18} style={{marginTop:"1rem"}}>*/}
            {/*            <Title className="" level={3}>Additional Information</Title>*/}
            {/*            <Form.Item*/}
            {/*                name="disease"*/}
            {/*                type="text"*/}
            {/*                className="mb-3"*/}
            {/*                label={<p className="color-secondary mb-0 fs-6">1. Does your relative have any of these conditions?</p>}*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                  required: true,*/}
            {/*                  message: 'Please select any of these!',*/}
            {/*                }]}*/}
            {/*              >*/}
            {/*                {loadDiesese ? <div className='text-center p-5'>*/}
            {/*                  <Spin size="large" /> */}
            {/*                  </div>*/}
            {/*                  :  */}
            {/*                  <CheckBoxComponent*/}
            {/*               values={hasDisease} */}
            {/*               onChange={(values) => {*/}
            {/*               setHasDisease(values)*/}
            {/*               setIsOther(values.includes('0'))*/}
            {/*               console.log("00 >", values)*/}
            {/*              }*/}
            {/*            }*/}
            {/*               items={diseaseData}*/}
            {/*               isRelativeModule*/}
            {/*               />*/}
            {/*               }*/}
            {/*              </Form.Item>*/}
            {/*              </Col>*/}
            {/*              <Col xs={24} style={{ marginTop: '1rem' }}>*/}
            {/*                {isOther && */}
            {/*                <Form.Item*/}
            {/*                name="otherDisease"*/}
            {/*                label="Other Disease"*/}
            {/*                type="text"*/}
            {/*                style={{maxWidth:"320px"}}*/}
            {/*                className="mb-3"*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                required: true,*/}
            {/*                message: 'Please enter your other disease!',*/}
            {/*                }]}*/}
            {/*                >*/}
            {/*                <Input size="large" placeholder="other disease"  />*/}
            {/*                </Form.Item>*/}
            {/*              }*/}
            {/*              </Col>*/}
            {/*                <Col xs={24} lg={24}>*/}
            {/*             <Form.Item*/}
            {/*                name="gender"*/}
            {/*                className="mb-3"*/}
            {/*                label={<p className="color-secondary mb-0 fs-6">2. Does your relative have a preference about the caregiver's gender? </p>}*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                  required: true,*/}
            {/*                  message: 'Please select preferred gender!',*/}
            {/*                }]}*/}
            {/*              >*/}
            {/*               <RadioBox */}
            {/*               btnClass={'border-less'}*/}
            {/*               onChange={(e) => {form.setFieldValue('gender', e.target.value);*/}
            {/*              //  setGenderState(e.target.value)*/}
            {/*              }}*/}
            {/*               options={genders}*/}
            {/*              //  value={genderState}*/}
            {/*               />*/}
            {/*              </Form.Item>*/}
            {/*              </Col>*/}
            {/*              <Col xs={24} lg={24}>*/}
            {/*              <Form.Item*/}
            {/*                name="pets"*/}
            {/*                className="mb-3"*/}
            {/*                label={<p className="color-secondary mb-0 fs-6">3. Does your relative have any pets? </p>}*/}
            {/*                rules={[*/}
            {/*                {*/}
            {/*                  required: true,*/}
            {/*                  message: 'Please select any of pets!',*/}
            {/*                }]}*/}
            {/*              >*/}

            {/*              <Checkbox.Group */}
            {/*              values={hasPets}*/}
            {/*                style={{ width: '100%' }}  onChange={(values) => {*/}
            {/*                  if(values.includes("PetFree")){*/}
            {/*                    setHasPets(["Pet Free"]);*/}
            {/*                    form.setFieldValue('pets', ["Pet Free"]);*/}
            {/*                  }else{*/}
            {/*                    setHasPets(values)*/}
            {/*                    form.setFieldValue('pets', values);*/}
            {/*                  }*/}
            {/*                  console.log("val", values)*/}
            {/*                }}>*/}
            {/*              <Button */}
            {/*                  size="large"*/}
            {/*                  className='me-2 mb-2 border-less text-body'*/}
            {/*                  disabled={hasPets?.includes("Pet Free")}*/}
            {/*                  >*/}
            {/*                  <img className='me-3' width={25} src='/images/icons/pets/cat.svg' alt='cat icon' /> Cat*/}
            {/*                  <Checkbox className='ms-2' value="Cat" */}
            {/*                  checked={hasPets?.includes("Cat")}*/}
            {/*                  ></Checkbox>*/}
            {/*              </Button>*/}
            {/*              <Button */}
            {/*                  size="large"*/}
            {/*                  className='me-2 mb-2 border-less text-body'*/}
            {/*                  disabled={hasPets?.includes("Pet Free")}*/}
            {/*                  >*/}
            {/*                  <img className='me-3' width={25} src='/images/icons/pets/dog.svg' alt='dog icon' /> Dogs*/}
            {/*                  <Checkbox className='ms-2' value="Dogs" */}
            {/*                  checked={hasPets?.includes("Dogs")}*/}
            {/*                  ></Checkbox>*/}
            {/*              </Button>*/}
            {/*              <Button */}
            {/*                  size="large"*/}
            {/*                  className='me-2 mb-2 border-less text-body'*/}
            {/*                  disabled={hasPets?.includes("Cat") || hasPets?.includes("Dogs")}*/}
            {/*                  >*/}
            {/*                  <img className='me-3' width={25} src='/images/icons/pets/pet-free.svg' alt='Pet Free icon' /> Pet Free*/}
            {/*                  <Checkbox className='ms-2' value="Pet Free" */}
            {/*                  checked={hasPets?.includes("Pet Free")}*/}
            {/*                  ></Checkbox>*/}
            {/*              </Button>*/}
            {/*              </Checkbox.Group>*/}
            {/*              </Form.Item>*/}
            {/*              </Col>*/}
            {/*              <Col xs={24} md={12} lg={24}>*/}
            {/*              <Form.Item*/}
            {/*                name="additionalInfo"*/}
            {/*                type="text"*/}
            {/*                label={<p className="color-secondary mb-0 fs-6">4. Any additional information or instructions not covered yet and deemed important for the caregivers to be aware of before the appointment?</p>}*/}
            {/*                className="mb-3"*/}
            {/*                // rules={[*/}
            {/*                // {*/}
            {/*                // required: true,*/}
            {/*                // message: 'Please enter additional information!',*/}
            {/*                // }]}*/}
            {/*                >*/}
            {/*                    <TextArea rows={4} placeholder="Type here" />*/}

            {/*                </Form.Item>*/}
            {/*                </Col>*/}
            {/*                <Col xs={24} md={12} lg={8} style={{ marginTop: '1rem' }}>*/}
            {/*                <Form.Item className="text-center mb-2" >*/}
            {/*                {props.ADDING_NEW ?*/}
            {/*                <div className='d-flex text-right px-3 mt-4' style={{ justifyContent: 'right' }}>*/}
            {/*                <Button*/}
            {/*                    onClick={() => navigate('/client/relatives')}*/}
            {/*                    className="btn-back auth-action-btn mt-3 mx-3" shape="round" size="large">*/}
            {/*                    Cancel*/}
            {/*                </Button>*/}
            {/*                <Button*/}
            {/*                    htmlType="submit"*/}
            {/*                    // onClick={handleSaveData}*/}
            {/*                    className="btn-black auth-action-btn mt-3" shape="round" type="primary" size="large">*/}
            {/*                    Add*/}
            {/*                </Button>*/}
            {/*              </div>*/}
            {/*                :*/}
            {/*                <div className='d-flex text-right px-3 mt-4' style={{ justifyContent: 'right' }}>*/}
            {/*                    <Button*/}
            {/*                        onClick={() => setDeleteConfirmation(true)}*/}
            {/*                        className="btn-back auth-action-btn mt-3 mx-3" shape="round" size="large">*/}
            {/*                        Delete*/}
            {/*                    </Button>*/}
            {/*                    <Button*/}
            {/*                        htmlType="submit"*/}
            {/*                        // onClick={handleSaveData}*/}
            {/*                        className="btn-black auth-action-btn mt-3" shape="round" type="primary" size="large">*/}
            {/*                        Save*/}
            {/*                    </Button>*/}
            {/*                  </div>*/}
            {/*                  }*/}
            {/*          </Form.Item>*/}
            {/*          </Col>*/}
            {/*          </Row>*/}
            {/*          </Form>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<DeleteConfirmationModal */}
            {/*                        deleteConfirmationVisible={deleteConfirmationVisible}*/}
            {/*                        handleDeleteModalClose={() => setDeleteConfirmation(false)}*/}
            {/*                        selectedRelative={relativeData}*/}
            {/*                        />*/}
            {/*</div>*/}
            {/*      ) : (*/}
            <div
                className="message-main"
                style={{background: "#F7F9FA", minHeight: "100vH"}}
            >
                <div className="container-fluid appointment-dashboard relative-list-container">
                    <div className="pt-5 d-flex client_Expenses">
                        <Sidebar/>
                        <div className="mx-4 message-inner-row w-100">
                            <AppointmentNavBar/>
                            <div
                                className="relative-form-wrapper relative-form-wrapper-custom"
                                style={{
                                    background: "#fff",
                                    padding: "40px 20px 10px 20px",
                                    borderRadius: "25px",
                                    height: "80vh",
                                    overflow: "auto",
                                }}
                            >
                                {/* <h1 className='color-primary fw-bolder mt-4'>Relative's Details</h1> */}
                                <ScrollWrapper
                                    className="relative-card-scroll"
                                    height="calc(80vh - 100px)"
                                >
                                    <div className="px-md-5 text-center position-relative">
                                        <Spin spinning={loadingProfile}>
                                            <div className="user-circle mx-auto">
                                                {console.log("profilePic", profilePic)}
                                                {!profilePic ? (<img
                                                        style={{
                                                            width: "100%", height: "100%", objectFit: "scale-down",
                                                        }}
                                                        src="/images/icons/Profile.png"
                                                        alt="dummy profile icon"
                                                    />) : (<img
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit: "scale-down",
                                                            borderRadius: "50%",
                                                        }}
                                                        src={profilePic}
                                                        alt="dummy profile icon"
                                                    />)}
                                                <label
                                                    type="file"
                                                    className="upload-file pointer"
                                                    style={{
                                                        position: "absolute", bottom: "0px", right: "0px",
                                                    }}
                                                >
                                                    <input
                                                        for="file"
                                                        className="btn-3"
                                                        onChange={handleImageUpload}
                                                        accept="image/*"
                                                        type="file"
                                                    />
                                                </label>
                                            </div>
                                        </Spin>
                                    </div>
                                    <div className="w-100 d-inline-block form-data-card">
                                        <Form
                                            name="signup-form"
                                            onFinish={handleSaveData}
                                            autoComplete="off"
                                            layout="vertical"
                                            // validateTrigger='onSubmit'
                                            onFieldsChange={handleFormChange}
                                            form={form}
                                            requiredMark={false}
                                            // initialValues={initialData}
                                            className="mb-0 p-md-4 text-start relative-form"
                                        >
                                            <Row gutter={[16, 0]}>
                                                <Col xs={24} md={12} lg={8}>
                                                    <Form.Item
                                                        name="firstName"
                                                        type="text"
                                                        className="mb-3"
                                                        label={<div>
                                                            First Name{" "}
                                                            <span style={{color: "red"}}>*</span>
                                                        </div>}
                                                        rules={[{
                                                            required: true, message: "Please enter your first name",
                                                        }, {
                                                            pattern: new RegExp(/^[A-Za-z ]+$/),
                                                            message: "Field does not accept numbers or special characters.",
                                                        },]}
                                                    >
                                                        <Input size="large" placeholder="First Name"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} lg={8}>
                                                    <Form.Item
                                                        name="lastName"
                                                        type="text"
                                                        label={<div>
                                                            Last Name{" "}
                                                            <span style={{color: "red"}}>*</span>
                                                        </div>}
                                                        className="mb-3"
                                                        rules={[{
                                                            required: true, message: "Please enter your last name",
                                                        }, {
                                                            pattern: new RegExp(/^[A-Za-z ]+$/),
                                                            message: "Field does not accept numbers or special characters.",
                                                        },]}
                                                    >
                                                        <Input size="large" placeholder="Last Name"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} lg={8}>
                                                    <Form.Item
                                                        name="dob"
                                                        type="text"
                                                        label={<div>
                                                            Date of Birth{" "}
                                                            <span style={{color: "red"}}>*</span>
                                                        </div>}
                                                        className="mb-3"
                                                        rules={[{
                                                            required: true, message: "Please enter your date of birth!",
                                                        },]}
                                                    >
                                                        <DatePicker
                                                            style={{width: "100%", height: "60px"}}
                                                            clearIcon={null}
                                                            suffixIcon={<img
                                                                src="/images/icons/calendar.svg"
                                                                alt="calendar"
                                                            />}
                                                            placeholder="Date of Birth"
                                                            format={"DD/MM/YYYY"}
                                                            disabledDate={(current) => {
                                                                return (current && current > moment().subtract(216, "month"));
                                                            }}
                                                            defaultPickerValue={form.getFieldValue("dob") ? moment(form.getFieldValue("dob")) : moment().set({year: 2000})}
                                                            onChange={(d) => form.setFieldValue("dob", d)}
                                                            size="large"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} lg={8}>
                                                    <Form.Item
                                                        name="phone_no"
                                                        type="text"
                                                        label="Phone No."
                                                        className="mb-3"
                                                        rules={[{
                                                            min: contryCode?.length + 10,
                                                            message: "Minimum 10 digits required.",
                                                        },]}
                                                    >
                                                        {/* {console.log("editRelData?.countryCode", editRelData?.countryCode)} */}
                                                        <PhoneInput
                                                            inputExtraProps={{
                                                                placeholder: "Enter phone number",
                                                                searchPlaceholder: "Enter phone number",
                                                            }}
                                                            placeholder="Enter phone number *"
                                                            searchPlaceholder="Enter phone number"
                                                            country={relativeData?.countryCode === undefined ? "ca" : relativeData?.countryCode}
                                                            value={(relativeData?.countryCode === undefined ? "" : relativeData?.countryCode) + relativeData?.phone}
                                                            enableAreaCodes={false}
                                                            copyNumbersOnly={false}
                                                            inputClass="ant-input ant-input-lg"
                                                            containerClass="ant-input-affix-wrapper"
                                                            dropdownClass="ant-select ant-select-single"
                                                            containerStyle={{padding: "2px 4px"}}
                                                            buttonStyle={{
                                                                background: "none", border: "none",
                                                            }}
                                                            inputStyle={{
                                                                width: "100%", paddingLeft: "60px",
                                                            }}
                                                            onChange={(value, country, e, formattedValue) => {
                                                                console.log(country, "fdsfdsf");
                                                                form.setFieldValue("phone", value.slice(country?.dialCode?.length));
                                                                if (value) {
                                                                    form.setFieldValue("countryCode", country?.dialCode);
                                                                    setCountryCode(country?.dialCode);
                                                                } else {
                                                                    setCountryCode("");
                                                                }
                                                                setMobile(value.slice(country?.dialCode?.length));
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} lg={8}>
                                                    <Form.Item
                                                        name="address"
                                                        type="text"
                                                        className="mb-3"
                                                        label={<div>
                                                            Address <span style={{color: "red"}}>*</span>
                                                        </div>}
                                                        rules={[{
                                                            required: true, message: "Please enter your address!",
                                                        },]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            style={{color: "black"}}
                                                            value={value}
                                                            onChange={(evt) => {
                                                                getPlacePredictions({
                                                                    input: evt.target.value,
                                                                });
                                                                if (evt.target.value) {
                                                                    SetShowList(true);
                                                                }
                                                                setValue(evt.target.value);
                                                            }}
                                                            onKeyDown={(event) => {
                                                                if (event?.keyCode === 8 || event?.keyCode === 46) {
                                                                    form.setFieldValue("city", "");
                                                                    form.setFieldValue("province", "");
                                                                    form.setFieldValue("postalCode", "");
                                                                    // handleFormChange()
                                                                }
                                                            }}
                                                            loading={isPlacePredictionsLoading}
                                                            placeholder="Address"
                                                        />
                                                        {!isPlacePredictionsLoading && showList && (
                                                            <div className="google-autosuggestion-list">
                                                                <>
                                                                    <List
                                                                        dataSource={placePredictions}
                                                                        renderItem={(item) => (<List.Item
                                                                                onClick={() => getDetails(item?.place_id)}
                                                                            >
                                                                                {/* {console.log("item", item, placePredictions)} */}
                                                                                <List.Item.Meta
                                                                                    title={item.description}
                                                                                />
                                                                            </List.Item>)}
                                                                    />
                                                                </>
                                                            </div>)}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} lg={8}>
                                                    <Form.Item
                                                        name={"apartmentNumber"}
                                                        label={<p className="color-secondary mb-0 fs-6">
                                                            Unit/Apartment Number
                                                        </p>}
                                                    >
                                                        <Input
                                                            placeholder="Unit/Apartment Number"
                                                            size="large"
                                                            className="profileinput"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} lg={8}>
                                                    <Form.Item
                                                        name="city"
                                                        type="text"
                                                        className="mb-3"
                                                        label={<div>
                                                            City <span style={{color: "red"}}>*</span>
                                                        </div>}
                                                        rules={[{
                                                            required: true, message: "Please enter your city!",
                                                        },]}
                                                    >
                                                        <Input disabled size="large" placeholder="City"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} lg={8}>
                                                    <Form.Item
                                                        name="province"
                                                        type="text"
                                                        label={<div>
                                                            Province <span style={{color: "red"}}>*</span>
                                                        </div>}
                                                        className="mb-3"
                                                        rules={[{
                                                            required: true, message: "Please enter your Province!",
                                                        },]}
                                                    >
                                                        <Input
                                                            disabled
                                                            size="large"
                                                            placeholder="Province"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} lg={8}>
                                                    <Form.Item
                                                        name="postalCode"
                                                        type="text"
                                                        label={<div>
                                                            Postal Code{" "}
                                                            <span style={{color: "red"}}>*</span>
                                                        </div>}
                                                        className="mb-3"
                                                        rules={[{
                                                            required: true, message: "Please enter your Postal Code!",
                                                        }, {
                                                            max: 7, message: "Maximum 7 digits can be entered",
                                                        }, {
                                                            pattern: new RegExp(/^[A-Za-z0-9 ]*$/), // message: "Please enter valid postal code",
                                                        },]}
                                                    >
                                                        <Input
                                                            // disabled
                                                            size="large"
                                                            placeholder="Postal Code"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={18}>
                                                    <Title
                                                        className="color-secondary mt-2 additional-info-tag"
                                                        level={3}
                                                    >
                                                        Additional Information
                                                    </Title>
                                                    <Form.Item
                                                        name="disease"
                                                        type="text"
                                                        className="mb-3"
                                                        label={<p className="color-secondary mb-0 fs-6">
                                                            1. Does the care recipient have any of these
                                                            conditions?{" "}
                                                            <span style={{color: "red"}}>*</span>
                                                        </p>}
                                                        rules={[{
                                                            required: true, message: "Select at least one option !",
                                                        },]}
                                                    >
                                                        {loadDiesese ? (<div className="text-center p-5">
                                                                <Spin size="large"/>
                                                            </div>) : (<CheckBoxComponent
                                                                values={hasDisease}
                                                                onChange={(values) => {
                                                                    const foundNone = diseaseData.find((i) => i.name == "None");
                                                                    if (values.includes(foundNone?._id)) {
                                                                        setHasDisease([foundNone?._id]);
                                                                    } else {
                                                                        setHasDisease(values);
                                                                        setIsOther(values.includes("0"));
                                                                        console.log("00 >", values);
                                                                    }
                                                                }}
                                                                items={diseaseData}
                                                                isRelativeModule
                                                            />)}
                                                    </Form.Item>
                                                </Col>
                                                {isOther && (<Col xs={24} lg={24}>
                                                        <Form.Item
                                                            name="otherDisease"
                                                            label="Other Disease"
                                                            type="text"
                                                            style={{maxWidth: "320px"}}
                                                            className="mb-3"
                                                            rules={[{
                                                                required: true,
                                                                message: "Please enter your other disease!",
                                                            },]}
                                                        >
                                                            <Input
                                                                size="large"
                                                                placeholder="Enter health condition(s) *"
                                                            />
                                                        </Form.Item>
                                                    </Col>)}
                                                <Col xs={24} lg={24} className="radio-btn-relative-div">
                                                    <Form.Item
                                                        name="gender"
                                                        className="mb-3"
                                                        label={<p className="color-secondary mb-0 fs-6">
                                                            2. Does the care recipient have a preference
                                                            regarding the caregiver's sex?{" "}
                                                            <span style={{color: "red"}}>*</span>
                                                        </p>}
                                                        rules={[{
                                                            required: true, message: "Please select preferred gender!",
                                                        },]}
                                                    >
                                                        <RadioBox
                                                            btnClass={"border-less"}
                                                            onChange={(e) => {
                                                                form.setFieldValue("gender", e.target.value);
                                                                //  setGenderState(e.target.value)
                                                            }}
                                                            options={genders}
                                                            //  value={genderState}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} lg={24} className="pets-radio-div">
                                                    <Form.Item
                                                        name="pets"
                                                        className="mb-3"
                                                        label={<p className="color-secondary mb-0 fs-6">
                                                            3. Does the care recipient have any pets?{" "}
                                                            <span style={{color: "red"}}>*</span>
                                                        </p>}
                                                        rules={[{
                                                            required: true, message: "Select at least one option!",
                                                        },]}
                                                    >
                                                        <Checkbox.Group
                                                            values={hasPets}
                                                            style={{width: "100%"}}
                                                            onChange={(values) => {
                                                                if (values.includes("PetFree")) {
                                                                    setHasPets(["Pet Free"]);
                                                                    form.setFieldValue("pets", ["Pet Free"]);
                                                                } else {
                                                                    setHasPets(values);
                                                                    form.setFieldValue("pets", values);
                                                                }
                                                                console.log("val", values);
                                                            }}
                                                        >
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item">
                                                                    <Checkbox
                                                                        style={{ flexDirection: "row-reverse" }}
                                                                        className="checkbox-custom-additional"
                                                                        value="Cat"
                                                                        checked={hasPets?.includes("Cat")}
                                                                        disabled={hasPets?.includes("Pet Free")}
                                                                    >
                                                                        <div className={`text-body pets-section-button ${hasPets?.includes("Cat") ? "checked" : ""}`}>
                                                                            <img className="me-3" width={25} src="/images/icons/pets/cat.svg" alt="cat icon"/>{" "}
                                                                            Cat
                                                                        </div>
                                                                    </Checkbox>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <Checkbox
                                                                        style={{ flexDirection: "row-reverse" }}
                                                                        className="checkbox-custom-additional"
                                                                        value="Dogs"
                                                                        checked={hasPets?.includes("Dogs")}
                                                                        disabled={hasPets?.includes("Pet Free")}
                                                                    >
                                                                        <div className={`text-body pets-section-button ${hasPets?.includes("Dogs") ? "checked" : ""}`}>
                                                                            <img className="me-3" width={25} src="/images/icons/pets/dog.svg" alt="dog icon"/>{" "}
                                                                            Dogs
                                                                        </div>
                                                                    </Checkbox>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <Checkbox
                                                                        style={{ flexDirection: "row-reverse" }}
                                                                        className="checkbox-custom-additional"
                                                                        value="Pet Free"
                                                                        checked={hasPets?.includes("Pet Free")}
                                                                        disabled={hasPets?.includes("Cat") || hasPets?.includes("Dogs")}
                                                                    >
                                                                        <div className={`text-body pets-section-button ${hasPets?.includes("Pet Free") ? "checked" : ""}`}>
                                                                            <img
                                                                                className="me-3"
                                                                                width={25}
                                                                                src="/images/icons/pets/pet-free.svg"
                                                                                alt="Pet Free icon"
                                                                            />{" "}
                                                                            Pet Free
                                                                        </div>
                                                                    </Checkbox>
                                                                </li>
                                                            </ul>
                                                        </Checkbox.Group>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={24} xl={12} lg={24}>
                                                    <Form.Item
                                                        name="additionalInfo"
                                                        type="text"
                                                        label={<p className="color-secondary mb-0 fs-6">
                                                            4. Any additional information or instructions
                                                            not covered yet and deemed important for the
                                                            caregivers to be aware of before the
                                                            appointment?{" "}
                                                            <span style={{color: "red"}}>*</span>
                                                        </p>}
                                                        className="mb-3"
                                                        // rules={[
                                                        // {
                                                        // required: true,
                                                        // message: 'Please enter additional information!',
                                                        // }]}
                                                    >
                                                        <TextArea
                                                            rows={5}
                                                            placeholder="Type here"
                                                            autoSize={{minRows: 5, maxRows: 10}}
                                                            style={{overflowY: "scroll"}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={24} lg={24} xs={24}>
                                                    <div className="buttons w-100">
                                                        <Form.Item className="text-center">
                                                            {props.ADDING_NEW ? (<div
                                                                    className="d-flex add-new-relative-div add-new-relative-div-update text-right w-100 px-3 px-0-mob"
                                                                    style={{
                                                                        justifyContent: "right",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        onClick={() => navigate("/client/relatives")}
                                                                        className="btn-back auth-action-btn add-new-relative-btn mt-3 mx-3"
                                                                        shape="round"
                                                                        size="large"
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                    <Button
                                                                        htmlType="submit"
                                                                        // onClick={handleSaveData}
                                                                        loading={saveloading}
                                                                        className="btn-black auth-action-btn add-new-relative-btn mt-3"
                                                                        shape="round"
                                                                        type="primary"
                                                                        size="large"
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </div>) : (<div
                                                                    className="d-flex add-new-relative-div add-new-relative-div-update text-right w-100 px-3 px-0-mob"
                                                                >
                                                                    <Button
                                                                        onClick={() => setDeleteConfirmation(true)}
                                                                        className="btn-back auth-action-btn add-new-relative-btn mt-3 mx-3"
                                                                        shape="round"
                                                                        size="large"
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                    <Button
                                                                        htmlType="submit"
                                                                        // onClick={handleSaveData}
                                                                        loading={saveloading}
                                                                        className="btn-black auth-action-btn add-new-relative-btn mt-3"
                                                                        shape="round"
                                                                        type="primary"
                                                                        size="large"
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                </div>)}
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </ScrollWrapper>
                            </div>
                            <Footer/>
                        </div>
                    </div>
                </div>
                <DeleteConfirmationModal
                    deleteConfirmationVisible={deleteConfirmationVisible}
                    handleDeleteModalClose={() => setDeleteConfirmation(false)}
                    selectedRelative={relativeData}
                />
            </div>
            {/*// )}*/}
        </>);
}
