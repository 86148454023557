import {Button, Form, Input, List, Select, Spin} from "antd";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {setAlert} from "../../../redux/alert/alert.action";
import {useDispatch, useSelector} from "react-redux";
import {fetchClientDetails} from "../../../redux/profileDetails/profile.action";
import "./settings.less";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {getAddressObject} from "../../../common/helper";
import {useNavigate} from "react-router-dom";
import Responsiveheader from "../../../components/Responsiveheader";

const ClientProfile = ({tabChange}) => {
    var token;
    token = localStorage.getItem("token");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const formRef = useRef();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const clientData = useSelector(
        (state) => state?.profileDetails?.getClientDetails?.output
    );
    const selectBefore = (
        <Select
            defaultValue={`+${clientData?.countryCode}`}
            disabled
            style={{width: 65}}
        ></Select>
    );
    useEffect(() => {
        dispatch(fetchClientDetails(token));
        // getDetails();
        console.log("address is", clientData?.address.split(",").splice(0, 1));
        formRef.current?.setFieldsValue(clientData);

        setValue(clientData?.address.split(",").slice(0, 1));
    }, [clientData?._id, clientData?.address, tabChange]);

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE,
        options: {
            componentRestrictions: {country: "IN"},
        },
    });
    const [value, setValue] = useState("");
    const [showList, SetShowList] = useState(false);

    const getDetails = (place_id) => {
        SetShowList(false);
        placesService?.getDetails(
            {
                placeId: place_id,
            },
            (placeDetails) => {
                console.log(
                    "placeDetails",
                    placeDetails,
                    placeDetails.geometry.location.lat(),
                    placeDetails.geometry.location.lng()
                );
                const address = getAddressObject(placeDetails?.address_components);
                setValue(placeDetails?.name);
                formRef.current.setFieldValue("address", placeDetails?.name);
                formRef.current.setFieldValue("city", address?.city);
                formRef.current.setFieldValue("province", address?.region);
                formRef.current.setFieldValue("postalCode", address?.postal_code);
                formRef.current.validateFields(["postalCode"]);
            }
        );
    };

    const onFinish = (values) => {
        // const newValues = { ...values };

        // const address=values.address.split(",");
        // newValues.city=address;
        // newValues.province=;
        // newValues.postalCode=address[3];
        updatedProfileDetails(values);
        console.log(values, "new Values of Client ");
    };

    const updatedProfileDetails = async (values) => {
        console.log("api values", values);
        setLoading(true);
        if (token !== "") {
            await axios
                .post(`${process.env.REACT_APP_BASE_URL}/user/update-profile`, values, {
                    headers: {
                        accessToken: token,
                        Authorization:
                            "Basic " +
                            btoa(
                                process.env.REACT_APP_USERNAME +
                                ":" +
                                process.env.REACT_APP_PASSWORD
                            ),
                    },
                })
                .then((response) => {
                    console.log("update profile", response);
                    if (response.data.status === 200) {
                        setLoading(false);
                        dispatch(fetchClientDetails(token));
                        dispatch(
                            setAlert({
                                alertType: "success",
                                alertMsg: "Details Updated Successfully",
                                alertOpen: true,
                            })
                        );
                    } else if (response.data.status >= 400) {
                        setLoading(false);
                        dispatch(
                            setAlert({
                                alertType: "error",
                                alertMsg: response?.data?.error?.message,
                                alertOpen: true,
                            })
                        );
                    } else {
                        setLoading(false);
                        dispatch(
                            setAlert({
                                alertType: "error",
                                alertMsg: "something went wrong! please try again.",
                                alertOpen: true,
                            })
                        );
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    if (
                        err?.response?.data?.status >= 400 &&
                        err?.response?.data?.status !== 401
                    ) {
                        dispatch(
                            setAlert({
                                alertType: "error",
                                alertMsg: err?.response?.data?.error?.message,
                                alertOpen: true,
                            })
                        );
                    }
                });
        }
    };

    const handleFormChange = (changedFields) => {
        if (changedFields[0]?.name[0]?.includes("postalCode")) {
            let val = changedFields[0]?.value;
            val = val.replace(/[^\w\s]/g, "");
            if (val[3] && val[3] != " ") {
                val = val?.replace(/(.{3})/, "$1 ");
            }
            formRef.current.setFieldValue("postalCode", val.toUpperCase());
        }
    };

    return (
        <>
            <div className="container clientprofile" style={{padding: "20px"}}>
                {/*{isMobile ? <Responsiveheader title="Edit Profile"/> : ""}*/}
                <Spin spinning={loading}>
                    <Form
                        layout="vertical"
                        ref={formRef}
                        onFinish={onFinish}
                        onFieldsChange={handleFormChange}
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <div className="profile-form">
                            <div className="subheading" style={{color: "#C0C0C0"}}>
                                First and Last Name
                            </div>
                            <div className="subform-1">
                                <Form.Item
                                    className="firstone"
                                    name={"firstName"}
                                    style={{backgroundColor: "#fff"}}
                                >
                                    <Input size="large" placeholder="First Name" disabled/>
                                </Form.Item>

                                <Form.Item
                                    className="lastone"
                                    name={"lastName"}
                                    style={{backgroundColor: "#fff"}}
                                >
                                    <Input size="large" placeholder="Last Name" disabled/>
                                </Form.Item>
                            </div>
                            <div className="subform-1">
                                <Form.Item
                                    label={"Phone Number"}
                                    className="firstone phonenum"
                                    style={{backgroundColor: "#fff", color: "#C0C0C0"}}
                                    disabled
                                >
                                    <Input
                                        size="large"
                                        disabled
                                        placeholder={`+${clientData?.countryCode} ${[
                                            clientData?.phone?.slice(0, 3),
                                            " ",
                                            clientData?.phone?.slice(3, 7),
                                            " ",
                                            clientData?.phone?.slice(7),
                                        ].join("")}`}
                                        //     addonAfter={<img src="/images/icons/tick.png" style={{ opacity: "0.5" }} />}
                                    />
                                    <span>
                    <img
                        alt=""
                        src="/images/icons/tick.png"
                        className="circle"
                        style={{opacity: "0.5"}}
                    />
                  </span>
                                </Form.Item>
                                <Form.Item
                                    className="lastone"
                                    label="Email Address"
                                    name={"email"}
                                    initialValue={clientData?.email}
                                    style={{color: "#C0C0C0", backgroundColor: "#fff"}}
                                >
                                    <Input
                                        size="large"
                                        placeholder={clientData?.email}
                                        disabled
                                    />
                                    <span className="circle">
                    {" "}
                                        <img alt="" src="/images/icons/tick.png"/>
                  </span>
                                </Form.Item>
                            </div>
                            <div className="addressheading">Address Details</div>
                            <div className="subform-1">
                                <Form.Item className="firstone editable" name="address">
                                    <Input
                                        size="large"
                                        style={{width: "100%", cursor: "text"}}
                                        value={value}
                                        onChange={(evt) => {
                                            getPlacePredictions({input: evt.target.value});
                                            SetShowList(true);
                                            setValue(evt.target.value);
                                        }}
                                        onKeyDown={(event) => {
                                            if (event?.keyCode === 8 || event?.keyCode === 46) {
                                                formRef.current.setFieldValue("city", "");
                                                formRef.current.setFieldValue("province", "");
                                                formRef.current.setFieldValue("postalCode", "");
                                                // handleFormChange()
                                            }
                                        }}
                                        loading={isPlacePredictionsLoading}
                                        placeholder="Address"
                                    />
                                    {!isPlacePredictionsLoading && value !== "" && showList && (
                                        <div className="google-autosuggestion-list">
                                            <>
                                                <List
                                                    dataSource={placePredictions}
                                                    renderItem={(item) => (
                                                        <List.Item
                                                            onClick={() => getDetails(item?.place_id)}
                                                        >
                                                            {/* {console.log("item", item, placePredictions)} */}
                                                            <List.Item.Meta title={item.description}/>
                                                        </List.Item>
                                                    )}
                                                />
                                            </>
                                        </div>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    name={"apartmentNumber"}
                                    className="lastone"
                                    initialValue={clientData?.apartmentNumber}
                                    // label={<p className="color-secondary mb-0 fs-6">Unit number/Apartment number</p>}
                                >
                                    <Input
                                        placeholder="Unit/Apartment Number"
                                        size="large"
                                    />
                                </Form.Item>
                            </div>
                            <div className="subform-1">
                                <Form.Item name="city" className="firstone ">
                                    <Input size="large" disabled style={{color: "black"}}/>
                                </Form.Item>
                                <Form.Item className="lastone" name="province">
                                    <Input size="large" disabled style={{color: "black"}}/>
                                </Form.Item>
                            </div>
                            <div className="subform-1" style={{width: "50%"}}>
                                <Form.Item
                                    className="lastone"
                                    name="postalCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your Postal Code!",
                                        },
                                        {
                                            max: 7,
                                            message: "Maximum 7 digits can be entered",
                                        },
                                        {
                                            pattern: new RegExp(/^[A-Za-z0-9 ]*$/),
                                            // message: "Please enter valid postal code",
                                        },
                                    ]}
                                >
                                    <Input size="large" style={{color: "black"}}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="client-bottom-btn client-bottom-btn-update fix-card-100-92">
                            <Button
                                className="btn-black  auth-action-btn float-end "
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                                shape="round"
                                size="large"
                            >
                                Save
                            </Button>
                        </div>
                    </Form>
                </Spin>
            </div>
        </>
    );
};
export default ClientProfile;
