import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getAddressObject } from "../../../../common/helper";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { setAlert } from "../../../../redux/alert/alert.action";
import { useNavigate } from "react-router-dom";
import { Logout, setUser } from "../../../../redux/auth/auth.action";
import "./profiledetails.less";

function ProfileDetails({
  save,
  sharePoints,
  accessToken,
  userDetails,
  userType,
  setUserType,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [firstModal, setFirstModal] = useState(false);
  const [genderState, setGenderState] = useState();
  const [disabledSave, setDisabledSave] = useState(true);
  const [showList, SetShowList] = useState(false);
  const [services, setServices] = useState([]);
  const [servicesSelected, setServicesSelected] = useState([]);
  const [value, setValue] = useState("");
  // const userDetails = useSelector((state) => state.loginData.user);
  const [formData, setFormData] = useState(userDetails);
  const [initDate, setInitDate] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [imagePopUpModal, setImagePopUpModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  console.log(userType, "userType");
  useEffect(() => {
    userType !== null && fetchServices(userType);
  }, [userType]);
  useEffect(() => {
    if (userDetails?.email) {
      setFormData(userDetails);
      console.log(userDetails, "userDetails");
      form.setFieldValue("firstName", userDetails?.firstName);
      form.setFieldValue("lastName", userDetails?.lastName);
      form.setFieldValue("gender", userDetails?.gender);
      console.log(userDetails?.firstName == undefined, "user frst nae");

      // if(userDetails?.firstName !==undefined)
      // {
      //   setFirstModal(false);
      // }
      // else
      // {
      //   setFirstModal(true);
      // }
      setGenderState(userDetails?.gender);
      if (userDetails?.dob) {
        form.setFieldValue("dob", moment(userDetails?.dob, "DD/MM/YYYY"));
      }
      setInitDate(
        userDetails !== undefined && userDetails?.dob === ""
          ? ""
          : moment(userDetails?.dob, "DD/MM/YYYY")
      );
      form.setFieldValue(
        "userType",
        userDetails?.userType === 0 ? null : userDetails?.userType
      );
      setUserType(userDetails?.userType);
      setValue(userDetails?.address);
      setServicesSelected(userDetails?.services);
      form.setFieldValue("services", userDetails?.services);
      form.setFieldValue("address", userDetails?.address);
      form.setFieldValue("city", userDetails?.city);
      form.setFieldValue("province", userDetails?.province);
      form.setFieldValue("postalCode", userDetails?.postalCode);
      form.setFieldValue("image", userDetails?.image);
      setProfilePic(userDetails?.image || "");

      handleFormChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);
  const fetchServices = (e) => {
    if (accessToken !== "") {
      setLoadingServices(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/service/list-services`,
          {
            type: e - 2,
          },
          {
            headers: {
              accessToken: accessToken,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setServices(response?.data?.output?.result);
            setLoadingServices(false);
          } else if (response.data.status >= 400) {
            console.log(response, "response in response");
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoadingServices(false);
          if (err?.response.data.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
            if (err.response.data.status === 401) {
              dispatch(Logout());
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          } else {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
          console.log("err", err);
        });
    }
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      componentRestrictions: { country: "IN" },
    },
  });

  const getDetails = (place_id) => {
    SetShowList(false);
    placesService?.getDetails(
      {
        placeId: place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components);
        setValue(placeDetails?.name);
        form.setFieldValue("address", placeDetails?.formatted_address);
        form.setFieldValue("city", address?.city);
        form.setFieldValue("province", address?.region);
        form.setFieldValue("postalCode", address?.postal_code);

        const location = {
          lat: placeDetails.geometry.location.lat(),
          lon: placeDetails.geometry.location.lng(),
        };
        sharePoints(location);
        handleFormChange();
      }
    );
  };

  const handleFormChange = (changedFields) => {
    if (
      changedFields?.length > 0 &&
      (changedFields[0]?.name[0]?.includes("firstName") ||
        changedFields[0]?.name[0]?.includes("lastName"))
    ) {
      const name = changedFields[0]?.name[0];
      let val = changedFields[0]?.value;
      val = val.charAt(0).toUpperCase() + val.slice(1);
      form.setFieldValue(name, val);
    }
    const valuesObj = form.getFieldsValue();
    delete valuesObj.apartmentNumber;
    const areTruthy = Object.values(valuesObj).every((value) => value);

    const hasErrors =
      !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const genders = [
    {
      icon: (
        <img
          className="me-3"
          width={25}
          src="/images/icons/female.svg"
          alt="female icon"
        />
      ),
      name: "Female",
      value: 2,
    },
    {
      icon: (
        <img
          className="me-3"
          width={25}
          src="/images/icons/male.svg"
          alt="male icon"
        />
      ),
      name: "Male",
      value: 1,
    },
  ];

  const onFinish = (values) => {
    console.log("values", values);
    localStorage.setItem("role", values?.userType);
    save(values);
  };
  const handleFirstModal = () => {
    setFirstModal(true);
  };
  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      setLoadingProfile(true);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("path", "test");
      console.log("formData", formData);
      if (accessToken !== "") {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user/upload-image`,
            formData,
            {
              headers: {
                accessToken: accessToken,
                Authorization:
                  "Basic " +
                  btoa(
                    process.env.REACT_APP_USERNAME +
                      ":" +
                      process.env.REACT_APP_PASSWORD
                  ),
              },
            }
          )
          .then((response) => {
            console.log("response", response);
            // console.log("response", response)
            if (response.data.status === 200) {
              setProfilePic(response.data.output.imageUrl1.location);
              form.setFieldValue(
                "image",
                response.data.output.imageUrl1.location
              );
              setLoadingProfile(false);
            } else if (response.data.status >= 400) {
              setLoadingProfile(false);
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: response?.data?.error?.message,
                  alertOpen: true,
                })
              );
            } else {
              setLoadingProfile(false);
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: "something went wrong! please try again.",
                  alertOpen: true,
                })
              );
            }
          })
          .catch((err) => {
            setLoadingProfile(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
            if (err.response.data.status === 401) {
              dispatch(Logout());
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          });
      }
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="px-3 mx-auto profile-details" style={{ maxWidth: "750px" }}>
      <h1 className="color-primary fw-bolder mt-4">Profile Details</h1>
      <div className="text-start form-row form-row-div">
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          // validateTrigger='onSubmit'
          initialValues={
            {
              // firstName:userDetails?.firstName,
              // lastName:userDetails?.lastName,
              // gender:'',
              // dob:'',
              // userType:'',
              // services:'',
              // address:'',
              // city:'',
              // province:'',
              // postalCode:''
            }
          }
          onFieldsChange={handleFormChange}
        >
          <Row gutter={16}>
            <Col xs={24} lg={24}>
              <Form.Item
                className="profileimage"
                name="image"
                rules={[
                  {
                    required: true,
                    message: "Please upload your profile image!",
                  },
                ]}
              >
                <div className="px-md-5 text-center position-relative">
                  <Spin spinning={loadingProfile}>
                    <div className="user-circle mx-auto">
                      {console.log("profilePic", profilePic)}
                      {profilePic === "" ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "scale-down",
                          }}
                          src="/images/icons/Profile.png"
                          alt="dummy profile icon"
                        />
                      ) : (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "scale-down",
                            borderRadius: "50%",
                          }}
                          src={profilePic}
                          alt="dummy profile icon"
                        />
                      )}
                      <label
                        type="file"
                        className="upload-file pointer"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "0px",
                        }}
                      >
                        {/* <Modal
        open={imagePopUpModal}
         onCancel={() => setImagePopUpModal(false)}
        footer={null}
        width={"350px"}
        height={"180px"}
        centered
   style={{top:"-100px"}}
      >
       
        <div className="crossimage" style={{display:"flex",alignItems:"flex-end",float:"right"}} onClick={()=>{setImagePopUpModal(false)}}> <label type="file"><img src="/images/icons/crossimage.png"  />
        <Input   for="file"
                           className="btn-3 d-none profileinput"
                           onChange={handleImageUpload}
                          accept="image/*"
                           type="file" />
                           </label>
        </div>
        <div style={{ textAlign: "center",display:"flex",flexDirection:"row",color:"#64748B" }}>
          <div className="rowone"  style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:"10%"}}>
          <img src="/images/icons/redcross.svg" style={{width:"25%",marginBottom:"8%"}}/>
          <img src="/images/image1.png" width={150} height={150} style={{borderRadius:"10px",marginBottom:"8%"}} alt=""></img>
          <p>Do not upload pictures that you have taken in selfie mode.</p><br />
          </div>
          <div className="rowone" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:"10%"}}>
          <img src="/images/icons/greentick.svg" style={{width:"25%",marginBottom:"8%"}}/>
          <img src="/images/image2.png" width={150} height={150} style={{borderRadius:"10px",marginBottom:"8%"}}alt=""></img>
          <p>Use front facing pictures with a plain background.</p><br />
          </div>
        </div>
       
      </Modal> */}
                        {/* <input  onClick={()=>{setImagePopUpModal(true)}}
                           for="file"
                          // className="btn-3 d-none"
                          // onChange={handleImageUpload}
                          // accept="image/*"
                          // type="file"
                        /> */}
                        <Input
                          for="file"
                          className="btn-3 d-none profileinput"
                          onChange={handleImageUpload}
                          accept="image/*"
                          type="file"
                        />
                      </label>
                    </div>
                  </Spin>
                </div>
              </Form.Item>
            </Col>
            {/* <span style={{height:"400px",overflowY:"auto"}} > */}
            <Row className="profile-header-row">
              <Col xs={22} md={24} lg={24} style={{ top: "10px" }}>
                <span className="info-tag" onClick={handleFirstModal}>
                  <img
                    alt=""
                    src="/images/icons/info-circle.svg"
                    style={{
                      marginLeft: "85%",
                      cursor: "pointer",
                      float: "right",
                      top: "20px !important",
                    }}
                  />
                </span>
              </Col>
              <Col xs={22} md={24} lg={12}>
                <Form.Item
                  name="firstName"
                  initialValue={formData?.firstName}
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      First Name <span style={{ color: "red" }}>*</span>
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name",
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z ]+$/),
                      message:
                        "Field does not accept numbers or special characters.",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="First Name"
                    className="profileinput"
                    onChange={(e) =>
                      form.setFieldValue("firstName", e.target.value)
                    }
                    style={{ width: "98%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={22} md={24} lg={12}>
                <Form.Item
                  name="lastName"
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter your last name",
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z ]+$/),
                      message:
                        "Field does not accept numbers or special characters.",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Last Name"
                    className="profileinput"
                    onChange={(e) =>
                      form.setFieldValue("lastName", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={22} md={24} lg={12} className="gender-section">
                <Form.Item
                  name="gender"
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      Specify Your Sex <span style={{ color: "red" }}>*</span>
                    </p>
                  }
                  rules={[{ required: true }]}
                  style={{ width: "98%" }}
                >
                  <Radio.Group
                    size="large"
                    className={`w-100 `}
                    buttonStyle={"outline"}
                    value={genderState}
                    onChange={(e) => {
                      form.setFieldValue("gender", e.target.value);
                      setGenderState(e.target.value);
                    }}
                  >
                    <Space
                      direction="horizontal"
                      className="custom-radio-space  w-100  profilegender"
                    >
                      {genders?.map((op, index) => {
                        return (
                          <Radio.Button
                            key={index}
                            className={` w-100 custom-radio size-change-radio text-dark`}
                            value={op?.value}
                          >
                            <span>
                              {genderState === op?.value
                                ? op?.activeIcon === undefined
                                  ? op?.icon
                                  : op?.activeIcon
                                : op?.icon}
                              {op?.name}
                            </span>
                            <span className="ms-2 ">
                              {genderState === op?.value ? (
                                <img
                                  width={16}
                                  src="/images/icons/checked.svg"
                                  alt="checked"
                                />
                              ) : (
                                <img
                                  width={16}
                                  src="/images/icons/unchecked.svg"
                                  alt="unchecked"
                                />
                              )}
                            </span>
                          </Radio.Button>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={22} md={24} lg={12}>
                <Form.Item
                  name="dob"
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      Date Of Birth <span style={{ color: "red" }}>*</span>
                    </p>
                  }
                  rules={[{ required: true }]}
                  initialValue={initDate}
                >
                  <DatePicker
                    className="w-100 profileinput"
                    size="large"
                    placeholder="Date Of Birth"
                    clearIcon={null}
                    onChange={(e) => {
                      form.setFieldValue("dob", moment(e, "DD/MM/YYYY"));
                      setInitDate(moment(e, "DD/MM/YYYY"));
                      handleFormChange();
                    }}
                    suffixIcon={
                      <img src="/images/icons/calendar.svg" alt="calendar" />
                    }
                    value={initDate}
                    format="DD/MM/YYYY"
                    defaultPickerValue={
                      form.getFieldValue("dob")
                        ? moment(form.getFieldValue("dob"))
                        : moment().subtract(216, "month")
                    }
                    disabledDate={(current) => {
                      return (
                        current && current > moment().subtract(216, "month")
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={22} md={24} lg={12}>
                <Form.Item
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      Select Your Role <span style={{ color: "red" }}>*</span>
                    </p>
                  }
                  name="userType"
                  rules={[{ required: true }]}
                  style={{ width: "98%" }}
                >
                  <Select
                    showArrow={true}
                    className="profileselectrole"
                    menuItemSelectedIcon={
                      <img
                        width={15}
                        src="/images/icons/checked.svg"
                        alt="arrow-icon"
                      />
                    }
                    suffixIcon={
                      <img
                        src="/images/icons/drop-icon.svg"
                        alt="arrow-icon"
                        className="arrowimage"
                      />
                    }
                    defaultValue={userType}
                    onChange={(e) => {
                      setUserType(e);
                      console.log(userType, "userType in servces");
                      setServicesSelected([]);
                      form.setFieldValue("services", []);
                      fetchServices(e);
                      handleFormChange();
                    }}
                    size="large"
                    placeholder="Select Your Role"
                  >
                    <Select.Option value={3} className="fw-bold">
                      Caregiver
                    </Select.Option>
                    <Select.Option value={4} className="fw-bold">
                      Companion
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={22} md={24} lg={12} className="service-section">
                <Form.Item
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      Which Services Would You Like to Offer?{" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                  }
                  name="services"
                  rules={[{ required: true, message: "Services are required" }]}
                >
                  <Spin spinning={loadingServices}>
                    <Select
                      suffixIcon={
                        <img
                          src="/images/icons/drop-icon.svg"
                          alt="arrow-icon"
                        />
                      }
                      size="large"
                      mode="multiple"
                      className="profileselect"
                      showArrow
                      style={{
                        width: "100%",
                      }}
                      defaultValue={userDetails?.services}
                      onChange={(value) => {
                        setServicesSelected(value);
                        form.setFieldValue("services", value);
                        handleFormChange();
                      }}
                      filterOption={(input, option) =>
                        option?.children?.props?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option?.props?.value
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={servicesSelected}
                      menuItemSelectedIcon={<CheckCircleFilled />}
                      placeholder="Select Your services"
                    >
                      {services?.length > 0 &&
                        services?.map((item, index) => {
                          return (
                            <Option value={item?._id} key={index}>
                              <div className="demo-option-label-item">
                                {item?.name}
                              </div>
                            </Option>
                          );
                        })}
                    </Select>
                  </Spin>
                </Form.Item>
              </Col>
              <Col xs={22} md={24} lg={12}>
                <Form.Item
                  name="address"
                  type="text"
                  className="mb-4"
                  style={{ width: "98%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter address!",
                    },
                  ]}
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      Address <span style={{ color: "red" }}>*</span>
                    </p>
                  }
                >
                  <Input
                    size="large"
                    style={{ color: "black", height: "60px" }}
                    defaultValue={userDetails?.address}
                    value={value}
                    onChange={(evt) => {
                      getPlacePredictions({ input: evt.target.value });
                      SetShowList(true);
                      setValue(evt.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event?.keyCode === 8 || event?.keyCode === 46) {
                        form.setFieldValue("city", "");
                        form.setFieldValue("province", "");
                        form.setFieldValue("postalCode", "");
                        handleFormChange();
                      }
                    }}
                    loading={isPlacePredictionsLoading}
                    placeholder="Address"
                  />
                  {!isPlacePredictionsLoading && value !== "" && showList && (
                    <div className="google-autosuggestion-list">
                      <>
                        <List
                          dataSource={placePredictions}
                          renderItem={(item) => (
                            <List.Item
                              onClick={() => getDetails(item?.place_id)}
                            >
                              <List.Item.Meta title={item.description} />
                            </List.Item>
                          )}
                        />
                      </>
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col xs={22} md={24} lg={12}>
                <Form.Item
                  name={"apartmentNumber"}
                  defaultValue={userDetails?.apartmentNumber}
                  style={{ width: "98%" }}
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      Unit/Apartment Number
                    </p>
                  }
                >
                  <Input
                    placeholder="Unit/Apartment Number"
                    size="large"
                    className="profileinput"
                  />
                </Form.Item>
              </Col>
              <Col xs={22} md={24} lg={12}>
                <Form.Item
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      City <span style={{ color: "red" }}>*</span>
                    </p>
                  }
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your city!",
                    },
                  ]}
                  style={{ width: "98%" }}
                >
                  <Input
                    disabled
                    size="large"
                    placeholder="City"
                    className="profileinput"
                  />
                </Form.Item>
              </Col>
              <Col xs={22} md={24} lg={12}>
                <Form.Item
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      Province <span style={{ color: "red" }}>*</span>
                    </p>
                  }
                  name="province"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Province!",
                    },
                  ]}
                  style={{ width: "98%" }}
                >
                  <Input
                    disabled
                    size="large"
                    placeholder="Province"
                    className="profileinput"
                  />
                </Form.Item>
              </Col>
              <Col xs={22} md={24} lg={12}>
                <Form.Item
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      Postal code <span style={{ color: "red" }}>*</span>
                    </p>
                  }
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Postal Code!",
                    },
                    // {
                    //   //   pattern: new RegExp(
                    //   //     /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
                    //   //   ),
                    //   message: "Please enter valid postal code",
                    // },
                  ]}
                >
                  <Input
                    disabled
                    size="large"
                    placeholder="Postal Code"
                    className="profileinput"
                  />
                </Form.Item>
              </Col>
              {/* </span> */}
              <Col xs={22} lg={24} md={24}>
                <Form.Item className="text-center mobile-btn-item mb-2">
                  <Button
                    className={
                      isMobile
                        ? "btn-black mt-3 mb-5 mx-auto mb-mob-0"
                        : "btn-black mt-3 mb-5 mx-auto"
                    }
                    shape="round"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={disabledSave}
                    style={{ marginLeft: "5rem!imporatant", width: "13rem" }}
                  >
                    Next{" "}
                    <img
                      className="ms-2"
                      src="/images/icons/right.svg"
                      alt="right icon"
                    />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </Form>
      </div>
      <Modal
        open={firstModal}
        // onCancel={() => setFirstModal(false)}
        // closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"355px"}
        height={"200px"}
        onCancel={() => setFirstModal(false)}
        centered
        closable
        closeIcon={<CloseCircleOutlined />}
        // style={{ top: 150 }}
      >
        <div style={{ textAlign: "center" }}>
          <img src="/images/icons/43.jpg" width={70} height={70} alt="" />
          <br />
          <br />
          <p>
            Please enter you legal first and last name in the boxes. Once you
            input your name it cannot be undone.
          </p>
          <br />
          <Button
            htmlType="submit"
            className="btn-black responsive-button understand-btn"
            style={{ marginLeft: "20%", padding: "10%" }}
            shape="round"
            size="large"
            onClick={() => setFirstModal(false)}
          >
            I understand
          </Button>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state?.loginData?.user?.accessToken,
  userDetails: state?.loginData?.user?.userDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
