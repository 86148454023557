import logo from "../../../../Images/Group 339268.jpg";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  List,
  Modal,
  Row,
  Select,
  Slider,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import "../settings.less";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../redux/alert/alert.action";
import axios from "axios";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  EditFilled,
  EditOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import CaregiverProfileAction from "../../../../redux/profileDetails/profile.action";
import "react-phone-input-2/lib/style.css";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getAddressObject } from "../../../../common/helper";
import languages from "../../../../common/helper/languages";
import { useNavigate } from "react-router-dom";
import CaregiverSettingGmap from "./CaregiverSettingGmap";
import moment from "moment";
import AppointmentNavBar from "../../../client/appointment/appointmentsNavBar";
import { updatedLanguageOptions } from "../../../../utils";

const CaregiverUpdatedProfile = ({
  careGiverExperienceList,
  qualificationsLists,
  handleImage,
  serviceData,
  setFirstModal,
  updatedProfileDetails,
  setQualificationImage,
  qualificationImage,
  isUploaded,
  setIsUploaded,
  months,
  loadingProfile,
  setLoadingProfile,
  tabChange,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  //  const [loadingProfile, setLoadingProfile] = useState(false)
  const [caregiverServices, setCaregiverServices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelImage, setModelImage] = useState("");
  const [disabledSave, setDisabledSave] = useState(true);
  const [profilePic, setProfilePic] = useState("");
  const [loading, setLoading] = useState(false);
  const [haveCar, setHaveCar] = useState(false);
  const [radius, setRadius] = useState();
  const [vaccinatedState, setVaccinatedState] = useState(false);
  const [expectedRate, setExpectedRate] = useState("");
  const [image, setImage] = useState("");
  const [spokenEnglishModel, setSpokenEnglishModel] = useState(false);
  const [languagesModel, setLanguagesModel] = useState(false);
  const [qualificationModel, setQualificationModel] = useState(false);
  const [experienceModel, setExperienceModel] = useState(false);
  const [referredFromModel, setRefferedFronModel] = useState(false);
  const [experience, setExperience] = useState("");
  const [services, setServices] = useState([]);
  const [englishProficiency, setEnglishProficiency] = useState("");
  const [marks, setMarks] = useState("");
  const [vaccinatedModal, setVaccinatedModal] = useState(false);
  const [languagesSpeaks, setLanguagesSpeaks] = useState([]);
  const [jobTitle, setJobTitle] = useState("");
  const [hearAboutUs, setHearAboutUs] = useState("");
  const [englishRating, setEnglishRating] = useState("");
  const [experienceYear, setExperienceYear] = useState("");
  const [qualification, setQualification] = useState([]);
  const [selectedServicesArr, setSelectedServicesArr] = useState([]);
  const [animalsModal, setAnimalsModal] = useState();
  const [animals, setAnimals] = useState("");
  const [qualImage, setQualImage] = useState("");
  const [dim, setDim] = useState();
  const [lat, setLat] = useState();
  const [lon, setLon] = useState();
  const [languageOptions, setLangOptions] = useState([]);
  const [isOtherVisible, setIsOtherVisible] = useState(false);
  const [otherVal, setOtherVal] = useState("");
  const [otherLan, setOtherLan] = useState(false);
  const [otherLanVal, setOtherLanval] = useState("");
  //  const [caregiverProfileDetails,setCaregiverProfileDetails]=useState();
  const caregiverProfileDetails = useSelector(
    (state) => state?.profileDetails?.getCaregiverDetails?.output
  );
  console.log(caregiverProfileDetails, "caregiverProfileDetails");
  const servicesSel = [];
  const selectedServiceArr = [];
  useEffect(() => {
    if (caregiverProfileDetails) {
      const langArr = [...updatedLanguageOptions];
      if (caregiverProfileDetails.languages?.length > 0) {
        caregiverProfileDetails.languages.forEach((lang) => {
          const foundLang = langArr.find(
            (newLang) => newLang.toLowerCase() == lang.toLowerCase()
          );
          if (!foundLang) {
            langArr.push(lang);
          }
        });
      }
      langArr.push("Other Languages");
      setLangOptions(langArr);
      const foundRefferedFrom = howDidYouHeardAboutUs.find(
        (i) => i == caregiverProfileDetails?.referredFrom
      );
      if (!foundRefferedFrom) {
        setHearAboutUs(caregiverProfileDetails?.referredFrom);
        // form.setFieldValue('referredFrom', caregiverProfileDetails?.referredFrom !== '' ? 'Others' : null);
        // form.setFieldValue('other', caregiverProfileDetails?.referredFrom)
        setIsOtherVisible(true);
        setOtherVal(caregiverProfileDetails?.referredFrom);
      } else {
        setHearAboutUs(
          caregiverProfileDetails?.referredFrom !== ""
            ? caregiverProfileDetails?.referredFrom
            : null
        );
      }
      dispatch(CaregiverProfileAction(token));
      setProfilePic(caregiverProfileDetails?.image);
      setHaveCar(caregiverProfileDetails?.haveCar);
      setRadius(caregiverProfileDetails?.location?.radius);
      setEnglishRating(caregiverProfileDetails?.englishRate);
      setExpectedRate(caregiverProfileDetails?.expectedRate);
      setExperience(caregiverProfileDetails?.experience);
      setQualificationImage(caregiverProfileDetails?.qualificationImage);
      setLanguagesSpeaks(caregiverProfileDetails?.languages);
      setExperienceYear(caregiverProfileDetails?.experience);
      setQualification(caregiverProfileDetails?.qualification);
      // setHearAboutUs(caregiverProfileDetails?.referredFrom);
      setVaccinatedState(caregiverProfileDetails?.isVaccinated);
      setSelectedServicesArr(caregiverProfileDetails?.selectedServices);
      setAnimals(caregiverProfileDetails?.animals);
      setQualImage(caregiverProfileDetails?.qualificationImage);
      setDim(
        caregiverProfileDetails?.location?.radius === 0
          ? 500
          : caregiverProfileDetails?.location?.radius * 100
      );
      setLat(caregiverProfileDetails?.location?.lat);
      setLon(caregiverProfileDetails?.location?.lon);
      handleFormChange();
      form.setFieldsValue({ ...caregiverProfileDetails });
      form.setFieldValue(
        "additionComments",
        caregiverProfileDetails?.about?.additionComments
      );
      form.setFieldValue("describe", caregiverProfileDetails?.about?.describe);
      form.setFieldValue(
        "experience1",
        caregiverProfileDetails?.about?.experience
      );
      setValue(caregiverProfileDetails?.address);
      caregiverProfileDetails?.selectedServices.map((item, id) =>
        servicesSel.push(item.name)
      );
      if (caregiverProfileDetails?.userType === 3) {
        setMarks(marksCare);
        setMin(18);
        setMax(25);
      } else {
        setMarks(marksComp);
        setMin(16);
        setMax(20);
      }
      setServices(servicesSel);
    }
  }, [
    caregiverProfileDetails?._id,
    caregiverProfileDetails?.userType,
    tabChange,
  ]);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      componentRestrictions: { country: "IN" },
    },
  });
  const [value, setValue] = useState("");
  const [showList, SetShowList] = useState(false);
  const handleServices = () => {
    for (let index = 0; index < serviceData?.length; index++) {
      const element = serviceData[index];
      for (let i = 0; i < caregiverServices?.length; i++) {
        const element2 = caregiverServices[i];
        if (element2 === element._id) {
          selectedServiceArr.push(element);
        }
      }
    }
    setSelectedServicesArr(selectedServiceArr);
    console.log(selectedServicesArr, "selected services");
  };

  const getDetails = (place_id) => {
    SetShowList(false);
    placesService?.getDetails(
      {
        placeId: place_id,
      },
      (placeDetails) => {
        setLat(placeDetails?.geometry?.location?.lat());
        setLon(placeDetails?.geometry?.location?.lng());
        //  console.log("placeDetails", placeDetails, placeDetails.geometry.location.lat(), placeDetails.geometry.location.lng())
        const address = getAddressObject(placeDetails?.address_components);
        setValue(placeDetails?.name);
        form.setFieldValue("address", placeDetails?.name);
        form.setFieldValue("city", address?.city);
        form.setFieldValue("province", address?.region);
        form.setFieldValue("postalCode", address?.postal_code);
      }
    );
  };

  const englishProficiencyLevels = [
    "Mother Tongue",
    "Fluent",
    "Proficient",
    "Conversational",
    "Basic",
  ];
  const marksCare = {
    18: "CAD 18",
    19: "CAD 19",
    20: "CAD 20",
    21: "CAD 21",
    22: "CAD 22",
    23: "CAD 23",
    24: "CAD 24",
    25: "CAD 25",
  };

  const marksComp = {
    16: "CAD 16",
    17: "CAD 17",
    18: "CAD 18",
    19: "CAD 19",
    20: "CAD 20",
  };

  const howDidYouHeardAboutUs = [
    "Existing MF Homecare Caregiver / Companion",
    "Relative/Friend",
    "Job Center",
    "Facebook",
    // "Twitter",
    "Internet Search",
    "Others",
  ];
  const formatter = (value) => `CAD ${value}`;

  const distance = {
    5: "5 Km",
    50: "20 Km",
  };
  const handleVaccinated = (values) => {
    setVaccinatedModal(false);
    if (values.isVaccinated == "Yes") {
      setVaccinatedState(true);
    } else {
      setVaccinatedState(false);
    }
  };
  const handleFormChange = () => {
    const areTruthy = Object.values(form.getFieldsValue()).every(
      (value) => value
    );

    const hasErrors =
      !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const onFinish = (values) => {
    const location = {
      lat: lat.toString(),
      lon: lon.toString(),
      radius: dim / 100,
    };
    var newValues = {
      ...values,
      isVaccinated: vaccinatedState,
      englishRate: englishRating,
      languages: languagesSpeaks,
      referredFrom: hearAboutUs,
      experience: experienceYear,
      qualification: qualification,
      qualificationImage: qualificationImage || "",
      haveCar: haveCar,
      animals: animals,
      location: location,
    };
    newValues["about"] = {
      describe: newValues.describe,
      additionComments: newValues.additionComments,
      experience: newValues.experience1,
    };
    // delete newValues.experience;
    delete newValues.describe;
    delete newValues.additionComments;
    // console.log(newValues, "newValues");
    updatedProfileDetails(newValues);
  };

  const handleCertificateUpload = (values) => {
    updatedProfileDetails(values);
  };
  const handleFinishEnglishRating = (values) => {
    setEnglishRating(values.englishRate);
    console.log(values.englishRate, "values.rate");
    console.log(englishRating, "english rating");
    setSpokenEnglishModel(false);
  };
  const handleLanguagesSpeak = (values) => {
    const filteredArr = values.languages.filter((i) => i != "Other Languages");
    if (otherLanVal) {
      setLanguagesSpeaks([...filteredArr, otherLanVal]);
    } else {
      setLanguagesSpeaks(filteredArr);
    }
    setLanguagesModel(false);
  };
  const handleExperienceyear = (values) => {
    setExperienceYear(values.experience);
    setExperienceModel(false);
  };
  const handleAnimalsfrom = (values) => {
    setAnimals(values.animals);
    setAnimalsModal(false);
  };
  const handleQualificationform = (values) => {
    setQualification(values.qualification);
    setQualificationModel(false);
  };
  const handleRefferedform = (values) => {
    const isOthers =
      howDidYouHeardAboutUs.find((i) => i == values.referredFrom) == "Others"
        ? otherVal
        : values.referredFrom;
    setHearAboutUs(isOthers);
    // form.setFieldValue('referredFrom', isOthers)
    setRefferedFronModel(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const showModel = () => {
    setIsModalOpen(true);
  };
  const handleQualifationImageForm = () => {
    //  handleImage();
    setModelImage(false);
    setQualImage(qualificationImage);
  };
  const handleModelQualificationImageForm = () => {
    setModelImage(true);
  };
  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    console.log(file, "uploaading file image");
    if (file) {
      setLoadingProfile(true);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("path", "test");
      console.log("formData", formData);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/user/upload-image`, formData, {
          headers: {
            accessToken: token,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        })
        .then((response) => {
          console.log("upload-image response", response);
          if (response.data.status === 200) {
            // console.log(response.data.output.imageUrl1.location, "image location")
            setProfilePic(response?.data?.output?.imageUrl1?.location);
            console.log(profilePic, "profilepic");
            form.setFieldValue(
              "image",
              response.data.output.imageUrl1.location
            );
            setLoadingProfile(false);
          } else if (response.data.status >= 400) {
            setLoadingProfile(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoadingProfile(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoadingProfile(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  const renderDots = (index, { style, className }) => {
    const dotStyle = {
      ...style,
      width: "1px",
      height: "12px",
      background: "#bfbfbf",
      marginLeft: "-0.5px",
      marginRight: "-0.5px",
    };
    const dots = [];
    for (let i = 0; i < 5; i++) {
      dots.push(
        <span
          key={`${index}-${i}`}
          className={className}
          style={{ ...dotStyle, height: "8px" }}
        />
      );
    }
    return dots;
  };
  const foundReferredVal = howDidYouHeardAboutUs.find(
    (i) => i == caregiverProfileDetails?.referredFrom
  );

  const handleLanguages = (languagesVal) => {
    // const languagesVal = form.getFieldValue('languages');
    console.log(languagesVal, "languagesVal");
    if (languagesVal.includes("Other Languages")) {
      console.log("setting language to");
      setOtherLan(true);
    } else {
      setOtherLan(false);
    }
  };
  return (
    // !upgrade?
    <>
      <div className="container clientprofile appointment-dashboard-clientprofile">
        {isMobile ? <AppointmentNavBar /> : ""}
        {/*<AppointmentNavBar/>*/}
        <div
          className="caregiver-profile-settings-card"
          style={{ overflowY: "scroll", height: "calc(87vh - 100px)" }}
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            onFieldsChange={handleFormChange}
            form={form}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
            }}
          >
            <div className="px-md-5 text-center position-relative">
              <Spin spinning={loadingProfile}>
                <div className="user-circle mx-auto">
                  {/* {console.log("profilePic", profilePic)} */}
                  {profilePic === "" ? (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "100px",
                      }}
                      src="/images/icons/Profile.png"
                      alt="dummy profile icon"
                    />
                  ) : (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      src={profilePic}
                      alt=" profile icon"
                    />
                  )}
                  <Form.Item name="image">
                    <label
                      type="file"
                      name="image"
                      className="upload-file pointer"
                      style={{
                        position: "absolute",
                        bottom: "-50px",
                        right: "0px",
                      }}
                    >
                      <input
                        for="file"
                        className="btn-3 d-none"
                        onChange={handleImageUpload}
                        accept="image/*"
                        type="file"
                        name="image"
                      />
                    </label>
                  </Form.Item>
                </div>
              </Spin>
            </div>
            <div
              className="container settings-editprofile"
              style={{ overflow: "hidden" }}
            >
              <Spin spinning={loadingProfile}>
                <div className="profile-form">
                  <div className="subheading" style={{ color: "#C0C0C0" }}>
                    First and Last Name
                  </div>
                  <div className="subform-1">
                    <Form.Item
                      className="firstone"
                      name={"firstName"}
                      style={{ backgroundColor: "#fff" }}
                      // initialValue={clientData?.firstName}f
                    >
                      <Input size="large" placeholder="First Name" disabled />
                    </Form.Item>

                    <Form.Item
                      className="lastone"
                      name={"lastName"}
                      style={{ backgroundColor: "#fff" }}
                      // initialValue={clientData?.lastName}
                    >
                      <Input size="large" placeholder="Last Name" disabled />
                    </Form.Item>
                  </div>
                  <div className="subform-1">
                    <Form.Item
                      label={"Phone Number"}
                      className="firstone"
                      style={{ backgroundColor: "#fff", color: "#C0C0C0" }}
                      disabled
                    >
                      <Input
                        size="large"
                        disabled
                        placeholder={`+${
                          caregiverProfileDetails?.countryCode
                        } ${[
                          caregiverProfileDetails?.phone?.slice(0, 3),
                          " ",
                          caregiverProfileDetails?.phone?.slice(3, 7),
                          " ",
                          caregiverProfileDetails?.phone?.slice(7),
                        ].join("")}`}
                        // addonAfter={<img src="/images/icons/Verified.png" style={{ opacity: "0.5" }} />}
                      />{" "}
                      <span className="circle">
                        {" "}
                        <img alt={""} src="/images/icons/Verified.png" />
                      </span>
                    </Form.Item>
                    <Form.Item
                      className="lastone"
                      label="Email Address"
                      name={"email"}
                      style={{ color: "#C0C0C0", backgroundColor: "#fff" }}
                    >
                      <Input
                        size="large"
                        placeholder={caregiverProfileDetails?.email}
                        disabled
                      />
                      <span className="circle">
                        {" "}
                        <img alt={""} src="/images/icons/Verified.png" />
                      </span>
                    </Form.Item>
                  </div>
                  <div className="dateofbirth" style={{ marginRight: "4px" }}>
                    <span>
                      {" "}
                      Date of Birth{" "}
                    </span>
                    <div className="date date-custom">
                      <Form.Item
                        width="10%"
                        className="dateitem dateitem-custom"
                      >
                        <Input
                          disabled
                          placeholder={
                            caregiverProfileDetails?.dob
                              ? moment(
                                  caregiverProfileDetails?.dob,
                                  "DD/MM/YYYY"
                                ).format("MMMM")
                              : "-"
                          }
                          //  placeholder={months[caregiverProfileDetails?.dob?.split("/")[1]]}
                        />
                      </Form.Item>
                      <Form.Item
                        width="10%"
                        className="dateitem dateitem-custom"
                      >
                        <Input
                          disabled
                          placeholder={
                            caregiverProfileDetails?.dob
                              ? moment(
                                  caregiverProfileDetails?.dob,
                                  "DD/MM/YYYY"
                                ).format("DD")
                              : "-"
                          }
                          // placeholder={caregiverProfileDetails?.dob.split("/")[0]}
                        />
                      </Form.Item>
                      <Form.Item
                        width="10%"
                        className="dateitem dateitem-custom"
                      >
                        <Input
                          disabled
                          placeholder={
                            caregiverProfileDetails?.dob
                              ? moment(
                                  caregiverProfileDetails?.dob,
                                  "DD/MM/YYYY"
                                ).format("YYYY")
                              : "-"
                          }
                          // placeholder={caregiverProfileDetails?.dob.split("/")[2]}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Spin>
              <span
                className="roleupgrade"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <span className="role" style={{ width: "70%" }}>
                  <p>
                    Role
                    {/* {
         caregiverProfileDetails?.userType==4?
          <span className="float-end">
                            <p style={{ textAlign: "end", cursor: "pointer" }} onClick={()=>setFirstModal(true)}
                                >Upgrade to Caregiver
                            </p>
                        </span>:""
                        } */}
                  </p>
                  <div
                    style={{
                      border: "1px solid rgb(0 0 0 / 10%)",
                      width: "30%",
                      borderRadius: "20px",
                      alignItems: "center",
                    }}
                    className="d-flex  caregiverrole"
                  >
                    <div className="caregiverpic">
                      <img
                        alt=""
                        src={logo}
                        width={46}
                        height={43}
                        style={{ position: "relative", top: "4px" }}
                      />
                    </div>
                    {caregiverProfileDetails?.userType == 4 ? (
                      <div
                        className="caregiverdetals"
                        style={{ alignItems: "center" }}
                      >
                        <spam className="role-name">
                          Companion
                          <br />
                        </spam>
                        <spam
                          style={{
                            fontSize: "13px",
                            fontStyle: "regular",
                            fontFamily: "open sans",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="caregiverdetals"
                        style={{ alignItems: "center" }}
                      >
                        <spam className="role-name">
                          Caregiver
                          <br />
                        </spam>
                        <spam
                          style={{
                            fontSize: "13px",
                            fontStyle: "regular",
                            fontFamily: "open sans",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </span>
                <span className="upgrade" style={{ width: "30%" }}>
                  {caregiverProfileDetails?.userType == 4 ? (
                    <span className="float-end">
                      <p
                        style={{ textAlign: "end", cursor: "pointer" }}
                        onClick={() => setFirstModal(true)}
                      >
                        Upgrade to Caregiver
                      </p>
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </span>
              <br />

              <Spin spinning={loadingProfile}>
                <h3
                  className="profile-detail-title"
                  style={{
                    color: "gray",
                    fontSize: "17px",
                  }}
                >
                  {caregiverProfileDetails?.userType == 4
                    ? "Companion Services "
                    : "Caregiver Services"}
                  <span className="float-end">
                    <EditOutlined onClick={showModel} />
                  </span>
                </h3>

                <Row
                  className="mt-3"
                  gutter={[24, 24]}
                  style={{ marginLeft: "0px" }}
                >
                  {selectedServicesArr?.length >= 0 &&
                    selectedServicesArr.map((i) => {
                      return (
                        <>
                          <Col
                            lg={12}
                            md={24}
                            xl={8}
                            sm={12}
                            xs={24}
                            style={{ paddingLeft: "0px" }}
                          >
                            <div
                              className="caregiverservice"
                              style={{
                                display: "flex",
                                borderRadius: "10px",
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                padding: "5px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="serviceimage"
                                style={{ marginRight: "10px" }}
                              >
                                <img
                                  alt={""}
                                  src={i.image}
                                  width={50}
                                  height={50}
                                />
                              </div>
                              <div className="servicename">
                                <span
                                  className="service-offer-text"
                                  style={{
                                    fontSize: "13px",
                                    fontFamily: "open sans",
                                    fontStyle: "regular",
                                  }}
                                >
                                  {i?.name}
                                </span>
                              </div>
                            </div>
                          </Col>
                        </>
                      );
                    })}
                </Row>
                <br />
              </Spin>
              <h4
                style={{ color: "gray", textAlign: "start", fontSize: "17px" }}
              >
                Address Details
              </h4>
              <div className="profile-form">
                <div className="subform-1">
                  <Form.Item
                    name={"address"}
                    defaultValue={caregiverProfileDetails?.address}
                    style={{ width: "80%" }}
                  >
                    <Input
                      size="large"
                      placeholder="Address"
                      value={value}
                      onChange={(evt) => {
                        getPlacePredictions({ input: evt.target.value });
                        SetShowList(true);
                        setValue(evt.target.value);
                      }}
                      onKeyDown={(event) => {
                        if (event?.keyCode === 8 || event?.keyCode === 46) {
                          form.setFieldValue("city", "");
                          form.setFieldValue("province", "");
                          form.setFieldValue("postalCode", "");
                          // handleFormChange()
                        }
                      }}
                      loading={isPlacePredictionsLoading}
                    />
                    {!isPlacePredictionsLoading && value !== "" && showList && (
                      <div className="google-autosuggestion-list">
                        <>
                          <List
                            dataSource={placePredictions}
                            renderItem={(item) => (
                              <List.Item
                                onClick={() => getDetails(item?.place_id)}
                              >
                                {/* {console.log("item", item, placePredictions)} */}
                                <List.Item.Meta title={item.description} />
                              </List.Item>
                            )}
                          />
                        </>
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item
                    name={"apartmentNumber"}
                    defaultValue={caregiverProfileDetails?.apartmentNumber}
                    style={{ width: "80%" }}
                  >
                    <Input
                      placeholder="Unit/Apartment Number"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name={"city"}
                    defaultValue={caregiverProfileDetails?.city}
                    style={{ width: "80%" }}
                  >
                    <Input
                      size="large"
                      placeholder="city"
                      disabled
                      style={{ color: "black" }}
                    />
                  </Form.Item>
                </div>
                <div className="subform-2">
                  <Form.Item
                    name={"province"}
                    initialValue={caregiverProfileDetails?.province}
                    style={{ width: "80%" }}
                  >
                    <Input
                      size="large"
                      placeholder="Province"
                      disabled
                      style={{ color: "black" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"postalCode"}
                    defaultValue={caregiverProfileDetails?.postalCode}
                    style={{ width: "80%" }}
                  >
                    <Input
                      size="large"
                      placeholder="Postal Code"
                      disabled
                      style={{ color: "black" }}
                    />
                  </Form.Item>
                </div>
              </div>

              <p style={{ color: "gray", fontSize: "16px" }}>
                How far are you willing to travel for appointments?
              </p>
              <br />
              {/* <Slider className="simple-slider" defaultValue={radius} name="radius"
              value={radius} marks={distance} min={5} max={20} step={1}
              onChange={(value) => { console.log(value, "onchange"); setRadius(value) }}
            /> */}

              <CaregiverSettingGmap
                caregiverProfileDetails={caregiverProfileDetails}
                dim={dim}
                setDim={setDim}
                setRadius={setRadius}
                radius={radius}
                lat={lat}
                lon={lon}
              />

              <br />
              <Spin spinning={loadingProfile}>
                <Form.Item initialValue={haveCar} name="haveCar">
                  <Paragraph
                    className="mobile-responsive-paragraph"
                    style={{ color: "bold", fontWeight: "bold" }}
                  >
                    Do you have a car? Would you like to offer driving services
                    for your clients’ appointments and errands?
                    <span className="float-end mobile-paragraph-checkbox">
                      <Checkbox
                        className="checkboxclass"
                        checked={haveCar}
                        value={haveCar}
                        onChange={(e) => {
                          form.setFieldValue("haveCar", e.target.checked);
                          console.log(typeof e.target.checked, "checked");
                          setHaveCar(e.target.checked);
                          console.log(haveCar, "have checkout");
                        }}
                      ></Checkbox>
                    </span>
                  </Paragraph>
                </Form.Item>

                {/* <Title style={{ color: "gray", textAlign: "start" ,fontSize: "17px",marginBottom:'20px'}}>Other Details</Title> */}
                <Title
                  className="profile-details-h4"
                  level={4}
                  style={{ fontSize: "17px", textAlign: "start" }}
                >
                  How Would you rate your spoken english?
                </Title>
                <Paragraph
                  className="profile-details-p"
                  style={{ fontSize: "15px", marginTop: "10px" }}
                >
                  {englishRating}
                  <span className="float-end">
                    <EditFilled onClick={(e) => setSpokenEnglishModel(true)} />
                  </span>
                </Paragraph>
                <Title
                  className="profile-details-h4"
                  level={4}
                  style={{ fontSize: "17px", textAlign: "start" }}
                >
                  What other languages do you speak?
                </Title>
                <Paragraph
                  className="profile-details-p"
                  style={{
                    fontSize: "15px",
                    marginTop: "10px",
                  }}
                >
                  {languagesSpeaks?.length > 0
                    ? languagesSpeaks?.join(", ")
                    : "N/A"}
                  <span className="float-end">
                    <EditFilled onClick={(e) => setLanguagesModel(true)} />
                  </span>
                </Paragraph>
                <Title
                  className="profile-details-h4"
                  level={4}
                  style={{ fontSize: "17px", textAlign: "start" }}
                >
                  Which pets are you comfortable being around?
                </Title>
                <Paragraph
                  className="profile-details-p"
                  style={{ fontSize: "15px", marginTop: "10px" }}
                >
                  {animals}
                  <span className="float-end">
                    <EditFilled onClick={(e) => setAnimalsModal(true)} />
                  </span>
                </Paragraph>
                {caregiverProfileDetails?.userType == 3 ? (
                  <div>
                    <Title
                      className="profile-details-h4"
                      level={4}
                      style={{ fontSize: "17px", textAlign: "start" }}
                    >
                      How many years of experience do you have as a caregiver?{" "}
                    </Title>
                    <Paragraph
                      className="profile-details-p"
                      style={{ fontSize: "15px", marginTop: "10px" }}
                    >
                      {experienceYear}
                      <span className="float-end">
                        <EditFilled onClick={(e) => setExperienceModel(true)} />
                      </span>
                    </Paragraph>
                    <Title
                      className="profile-details-h4"
                      level={4}
                      style={{ fontSize: "17px", textAlign: "start" }}
                    >
                      What was your job title?
                    </Title>
                    <Paragraph
                      className="profile-details-p"
                      style={{
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      {qualification?.length > 0
                        ? qualification?.join(", ")
                        : "N/A"}
                      <span className="float-end">
                        <EditFilled
                          onClick={(e) => setQualificationModel(true)}
                        />
                      </span>
                    </Paragraph>
                  </div>
                ) : (
                  ""
                )}
                <br />

                {caregiverProfileDetails?.userType == 3 ? (
                  <div className="">
                    <span
                      className="float-end icon-position"
                      onClick={() => setModelImage(true)}
                    >
                      <EditFilled />
                    </span>

                    {/* {console.log("api certificate", caregiverProfileDetails?.qualificationImage)} */}
                    {qualImage ? (
                      <Form.Item>
                        {" "}
                        <img
                          src={qualImage}
                          alt=""
                          height="150"
                          width="150px"
                          style={{ borderRadius: "10px" }}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item>
                        {" "}
                        <img
                          alt={""}
                          src="/images/icons/qualplaceholder.png"
                          height="150px"
                          width="150px"
                        />
                      </Form.Item>
                    )}
                    <br />
                  </div>
                ) : (
                  ""
                )}
                <h4
                  style={{
                    color: "gray",
                    textAlign: "start",
                    fontSize: "15px",
                  }}
                >
                  About Yourself
                </h4>
                <br />
                <h4
                  style={{
                    color: "gray",
                    textAlign: "start",
                    fontSize: "15px",
                  }}
                >
                  How would you describe yourself?
                </h4>
                <Form.Item
                  name="describe"
                  initialValue={caregiverProfileDetails?.about?.describe}
                >
                  <TextArea
                    rows={5}
                    placeholder="Describe who you are"
                    style={{ marginTop: "10px" }}
                  />
                </Form.Item>
                <h4
                  style={{
                    color: "gray",
                    textAlign: "start",
                    fontSize: "15px",
                  }}
                >
                  Experience
                </h4>
                <Form.Item
                  name="experience1"
                  initialValue={caregiverProfileDetails?.about?.experience}
                >
                  <TextArea
                    rows={5}
                    style={{ marginTop: "10px" }}
                    placeholder="write about your experience"
                  />
                </Form.Item>
                <h4
                  style={{
                    color: "gray",
                    textAlign: "start",
                    fontSize: "15px",
                  }}
                >
                  Additional Comments
                </h4>
                <Form.Item
                  name="additionComments"
                  initialValue={
                    caregiverProfileDetails?.about?.additionComments
                  }
                >
                  <TextArea
                    rows={5}
                    placeholder="add some additional comments"
                    style={{ marginTop: "10px" }}
                  />
                </Form.Item>
                <Title
                  className="profile-details-h4"
                  level={4}
                  style={{ fontSize: "17px", textAlign: "start" }}
                >
                  How did you hear about us?
                </Title>
                <Paragraph
                  className="profile-details-p"
                  style={{ fontSize: "15px", marginTop: "10px" }}
                >
                  {hearAboutUs}
                  <span className="float-end">
                    <EditFilled onClick={(e) => setRefferedFronModel(true)} />
                  </span>
                </Paragraph>
                {/* <Title
                  className="profile-details-h4"
                  level={4}
                  style={{ fontSize: "17px", textAlign: "start" }}
                >
                  {" "}
                  Are you fully vaccinated?
                </Title>
                <Paragraph
                  className="profile-details-p"
                  style={{ fontSize: "15px", marginTop: "10px" }}
                >
                  {vaccinatedState ? "Yes" : "No"}
                  <span className="float-end">
                    <EditFilled onClick={(e) => setVaccinatedModal(true)} />
                  </span>
                </Paragraph> */}
                <Form.Item
                  label={<h2 className="">Set your hourly rate</h2>}
                  name="expectedRate"
                  initialValue={expectedRate}
                >
                  <br />
                  <Slider
                    className="update-profile-slider"
                    range
                    value={expectedRate}
                    onChange={(val) => {
                      form.setFieldValue("expectedRate", Number(val));
                      setExpectedRate(val);
                      handleFormChange();
                    }}
                    tooltip={{ formatter }}
                    marks={marks}
                    min={min}
                    max={max}
                    dots={{ render: renderDots }}
                    included
                    trackStyle={{ backgroundColor: "#1890ff" }}
                  />

                  <br />
                  <p
                    className="color-secondary mt-2"
                    style={{ fontSize: "12px" }}
                  >
                    Until you build a client list with positive reviews and
                    ratings, it is recommended to set your rate reasonably to
                    maximize appointment requests.
                  </p>
                </Form.Item>
              </Spin>

              <br className="d-none-mob" />
              <br className="d-none-mob" />
              <br className="d-none-mob" />
              <div className="btn-position settings-button-position settings-button-position-profile">
                <Button
                  className="ant-btn ant-btn-round ant-btn-primary ant-btn-lg btn-black  auth-action-btn mt-5  float-end caregiversave"
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size="large"
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Modal
        open={spokenEnglishModel}
        onCancel={(e) => setSpokenEnglishModel(false)}
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"400px"}
        height={"614px"}
        centered
        style={{ top: 150, minHeight: "100%", overflowY: "auto" }}
      >
        <>
          <Form
            layout="vertical"
            onFinish={(values) => {
              return handleFinishEnglishRating(values);
            }}
          >
            <Form.Item
              label="How Would you rate your spoken english?"
              name={"englishRate"}
              initialValue={englishRating}
            >
              <Select
                size="large"
                showArrow
                menuItemSelectedIcon={<CheckCircleFilled />}
                placeholder="Select your preference"
                onChange={(e) => {
                  form.setFieldValue("englishRate", e);
                }}
              >
                {englishProficiencyLevels.map((item) => {
                  return (
                    <Select.Option value={item}>
                      <div className="demo-option-label-item">{item}</div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Button
              htmlType="submit"
              className="btn-black auth-action-btn "
              style={{ width: "38%", marginLeft: "30%" }}
              shape="round"
              size="large"
            >
              Save
            </Button>
          </Form>
        </>
      </Modal>
      <Modal
        open={isModalOpen}
        onCancel={(e) => setIsModalOpen(false)}
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"400px"}
        height={"614px"}
        centered
        style={{
          top: 150,
          minHeight: "100%",
          overflowY: "auto",
        }}
      >
        <div style={{ marginTop: "20px" }}>
          <h2 className="text-center">Update Your Services</h2>
          <Form
            layout="vertical"
            form={form}
            onFinish={(values) => {
              return setIsModalOpen(false);
            }}
          >
            <Form.Item
              label="select your services"
              name={"services"}
              initialValue={services}
            >
              <Select
                size="large"
                mode="multiple"
                menuItemSelectedIcon={<CheckCircleFilled />}
                placeholder="Select your preference"
                onChange={(value) => {
                  console.log(value, "value on");
                  form.setFieldValue("services", value);
                  setCaregiverServices(value);
                  setServices(value);
                }}
              >
                {serviceData?.length > 0 &&
                  serviceData?.map((item, index) => {
                    return (
                      <Select.Option value={item._id} key={item.index}>
                        <div className="demo-option-label-item">
                          {item.name}
                        </div>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Button
              htmlType="submit"
              className="btn-black auth-action-btn "
              style={{ width: "38%", marginLeft: "30%" }}
              shape="round"
              size="large"
              onClick={() => {
                handleServices();
              }}
            >
              Save
            </Button>
          </Form>
        </div>
      </Modal>

      <Modal
        open={modelImage}
        onCancel={(e) => setModelImage(false)}
        closable
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"400px"}
        height={"714px"}
        centered
        style={{ top: 200 }}
        className="imagemodal"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* <div>
                        <img src="/images/icons/crossimage.png" alt="cross image"
                             style={{float: "right", width: "6%", marginBottom: "10px", cursor: "pointer"}}
                             onClick={() => {
                                 setModelImage(false)
                             }}/>
                    </div> */}
          <div>
            <Spin spinning={loadingProfile}>
              <Form
                name="qualificationImage"
                onFinish={() => {
                  return handleQualifationImageForm();
                }}
                form={form}
              >
                <Form.Item
                  name="qualificationImage"
                  rules={[{ required: true }]}
                >
                  <div className="file-upload">
                    {!isUploaded ? (
                      <>
                        {/* <label type='file' className="upload-file-field pointer" placeholder="Upload file" */}
                        {/* style={{ marginTop: "320px", width: "80%" }}> */}
                        {/* form.setFieldValue('qualificationImage', val); */}
                        <Image src="/images/icons/file-upload.png" />
                        <br />
                        <input
                          id="file"
                          onChange={(val) => {
                            handleImage(val);
                          }}
                          className="btn-3"
                          accept="image/*"
                          type="file"
                          style={{
                            marginLeft: "120px",
                            marginTop: "40px",
                            display: "none",
                          }}
                        />
                        <label
                          for="file"
                          style={{
                            padding: "15px",
                            backgroundColor: "#364BC6",
                            borderRadius: "25px",
                            marginTop: "60px",
                            color: "white",
                          }}
                        >
                          Upload file
                        </label>

                        {/* <img src="/images/icons/upload-icon.svg" alt="" style={{ marginLeft: "250px"}} /> */}
                        {/* </label> */}
                      </>
                    ) : (
                      <>
                        <EditFilled
                          alt=""
                          onClick={() => [setIsUploaded(false), setImage(null)]}
                          className="close-icon"
                        />
                        {/* <img src="/images/icons/closeIcon.svg" alt='' onClick={() => [setIsUploaded(false), setImage(null)]} className="close-icon" /> */}
                        {loadingProfile ? (
                          ""
                        ) : (
                          <img
                            src={qualificationImage}
                            alt=""
                            className="upload-image"
                          />
                        )}
                      </>
                    )}
                  </div>
                </Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-black auth-action-btn "
                  shape="round"
                  size="large"
                  style={{ width: "38%", marginLeft: "34%" }}
                >
                  Save
                </Button>
              </Form>
            </Spin>
          </div>
        </div>
      </Modal>

      <Modal
        open={languagesModel}
        onCancel={(e) => setLanguagesModel(false)}
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"400px"}
        centered
        style={{ top: 150, minHeight: "200%", overflowY: "auto" }}
      >
        <>
          <Form
            layout="vertical"
            onFinish={(values) => {
              return handleLanguagesSpeak(values);
            }}
          >
            <Form.Item
              label="What other laguages do you speak?"
              name={"languages"}
              initialValue={languagesSpeaks}
            >
              <Select
                size="large"
                mode="multiple"
                menuItemSelectedIcon={<CheckCircleFilled />}
                placeholder="Select your preference"
                onChange={(e) => {
                  handleLanguages(e);
                  form.setFieldValue("languages", e);
                }}
              >
                {languageOptions?.length > 0 &&
                  languageOptions?.map((item) => {
                    return (
                      <Select.Option value={item}>
                        <div className="demo-option-label-item">{item}</div>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            {otherLan && (
              <Form.Item
                className="other-language-input"
                name="other"
                rules={[
                  {
                    pattern: new RegExp(/^[A-Za-z]+$/),
                    message: "Add only alphabets",
                  },
                  { required: true, message: "Other Language is Required" },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Enter language"
                  style={{
                    width: "97%",
                    marginTop: "10px",
                    marginLeft: "10px",
                    height: "40px",
                    top: "-15px",
                  }}
                  onChange={(e) => {
                    setOtherLanval(e.target.value);
                  }}
                />
              </Form.Item>
            )}
            <Button
              htmlType="submit"
              className="btn-black auth-action-btn "
              // style={{ marginLeft: "80px" }}
              style={{ width: "38%", marginLeft: "30%" }}
              shape="round"
              size="large"
            >
              Save
            </Button>
          </Form>
        </>
      </Modal>

      <Modal
        open={experienceModel}
        onCancel={(e) => setExperienceModel(false)}
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"400px"}
        height={"614px"}
        centered
        style={{ top: 150 }}
      >
        <>
          <Form
            layout="vertical"
            onFinish={(values) => {
              return handleExperienceyear(values);
            }}
          >
            <Form.Item
              label="How many years of experience do you have as a Caregiver?"
              name={"experience"}
              initialValue={experienceYear}
            >
              <Select
                size="large"
                showArrow
                menuItemSelectedIcon={<CheckCircleFilled />}
                placeholder="Select your preference"
                value={experience}
                onChange={(e) => {
                  form.setFieldValue("experience", e);
                }}
              >
                {careGiverExperienceList.map((item) => {
                  return (
                    <Select.Option value={item}>
                      <div className="demo-option-label-item">{item}</div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Button
              htmlType="submit"
              className="btn-black auth-action-btn center "
              shape="round"
              size="large"
              style={{ width: "38%", marginLeft: "30%" }}
            >
              Save
            </Button>
          </Form>
        </>
      </Modal>

      <Modal
        open={qualificationModel}
        onCancel={(e) => setQualificationModel(false)}
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"400px"}
        height={"614px"}
        centered
        style={{ top: 150 }}
      >
        <>
          <Form
            layout="vertical"
            onFinish={(values) => {
              return handleQualificationform(values);
            }}
          >
            <Form.Item
              label="What was your job title?"
              name={"qualification"}
              initialValue={qualification}
            >
              <Select
                size="la"
                mode="multiple"
                showArrow
                menuItemSelectedIcon={<CheckCircleFilled />}
                placeholder="Select qualification"
                onChange={(e) => {
                  form.setFieldValue(qualification);
                }}
              >
                {qualificationsLists.map((item) => {
                  return (
                    <Select.Option value={item}>
                      <div className="demo-option-label-item">{item}</div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Button
              htmlType="submit"
              className="btn-black auth-action-btn "
              //style={{ marginLeft: "80px" }}
              style={{ width: "38%", marginLeft: "30%" }}
              shape="round"
              size="large"
            >
              Save
            </Button>
          </Form>
        </>
      </Modal>

      <Modal
        open={referredFromModel}
        onCancel={(e) => setRefferedFronModel(false)}
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"400px"}
        height={"614px"}
        centered
        style={{ top: 150 }}
      >
        <>
          <Form
            layout="vertical"
            onFinish={(values) => {
              console.log(values);
              return handleRefferedform(values);
            }}
          >
            <Form.Item
              label="2. How did you hear about us?"
              name={"referredFrom"}
              initialValue={
                foundReferredVal
                  ? caregiverProfileDetails?.referredFrom
                  : "Others"
              }
            >
              <Select
                size="large"
                showSearch
                showArrow
                menuItemSelectedIcon={<CheckCircleFilled />}
                placeholder="Select your preference"
                onChange={(e) => {
                  if (e === "Others") {
                    setIsOtherVisible(true);
                    setOtherVal("");
                    // form.setFieldValue('other', "")
                  } else {
                    setIsOtherVisible(false);
                  }
                  form.setFieldValue("referredFrom", e);
                }}
              >
                {howDidYouHeardAboutUs.map((item) => {
                  return (
                    <Select.Option value={item}>
                      <div className="demo-option-label-item">{item}</div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {isOtherVisible && (
              <Form.Item
                // className="other-language-input"
                // name="other"
                rules={[
                  {
                    pattern: new RegExp(/^[A-Za-z]+$/),
                    message: "Add only alphabets",
                  },
                  { required: true, message: "Other field is Required" },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Enter source"
                  style={{
                    // width: "97%",
                    marginTop: "10px",
                    // marginLeft: "10px",
                    height: "40px",
                    top: "-15px",
                  }}
                  value={otherVal}
                  onChange={(e) => {
                    setOtherVal(e.target.value);
                  }}
                />
              </Form.Item>
            )}
            <Button
              htmlType="submit"
              className="btn-black auth-action-btn "
              //style={{ marginLeft: "80px" }}
              style={{ width: "38%", marginLeft: "30%" }}
              shape="round"
              size="large"
            >
              Save
            </Button>
          </Form>
        </>
      </Modal>
      <Modal
        open={vaccinatedModal}
        onCancel={(e) => setVaccinatedModal(false)}
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"400px"}
        height={"614px"}
        centered
        style={{ top: 150 }}
      >
        <Form
          layout="vertical"
          onFinish={(values) => {
            return handleVaccinated(values);
          }}
        >
          <Form.Item
            label="Are You Fully Vaccinated?"
            name={"isVaccinated"}
            initialValue={vaccinatedState ? "yes" : "No"}
          >
            <Select
              size="large"
              showSearch
              showArrow
              menuItemSelectedIcon={<CheckCircleFilled />}
              placeholder="Select your preference"
              // onChange={(e) => { e === 'Yes' ? setVaccinatedState(true) : setVaccinatedState(false) }}
            >
              {["Yes", "No"].map((item) => {
                return (
                  <Select.Option value={item}>
                    <div className="demo-option-label-item">{item}</div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Button
            htmlType="submit"
            className="btn-black auth-action-btn "
            // style={{ marginLeft: "80px" }}
            style={{ width: "38%", marginLeft: "30%" }}
            shape="round"
            size="large"
          >
            Save
          </Button>
        </Form>
      </Modal>
      <Modal
        open={animalsModal}
        onCancel={(e) => setAnimalsModal(false)}
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"400px"}
        height={"614px"}
        centered
        style={{ top: 150 }}
      >
        <Form
          layout="vertical"
          onFinish={(values) => {
            return handleAnimalsfrom(values);
          }}
        >
          <Form.Item
            label="What animals are you fine being around?"
            name={"animals"}
            initialValue={caregiverProfileDetails?.animals}
          >
            <Select
              size="large"
              showSearch
              showArrow
              menuItemSelectedIcon={<CheckCircleFilled />}
              placeholder="Select your preference"
              onChange={(e) => {
                form.setFieldValue("animals", e);
              }}
            >
              {["All", "Dogs", "Cats", "None"].map((item) => {
                return (
                  <Select.Option value={item}>
                    <div className="demo-option-label-item">{item}</div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Button
            htmlType="submit"
            className="btn-black auth-action-btn "
            style={{ width: "38%", marginLeft: "30%" }}
            shape="round"
            size="large"
          >
            Save
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default CaregiverUpdatedProfile;
