import { Button, Col, DatePicker, Form, Input, List, Row, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { setAlert } from "../../../../redux/alert/alert.action";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getAddressObject } from "../../../../common/helper";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../../../redux/auth/auth.action";

function AddRelativeProfileDetails({ accessToken, save, editRelData }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      componentRestrictions: { country: "IN" },
    },
  });

  const [profilePic, setProfilePic] = useState("");
  const [disabledSave, setDisabledSave] = useState(true);
  const [contryCode, setCountryCode] = useState("");
  const [mobile, setMobile] = useState(0);
  const [value, setValue] = useState("");
  const [showList, SetShowList] = useState(false);
  const [geocode, setGeocode] = useState();
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [initialData, setInitialData] = useState({
    firstName: editRelData !== undefined ? editRelData?.firstName : "",
    lastName: editRelData !== undefined ? editRelData?.lastName : "",
    dob:
      editRelData !== undefined ? moment(editRelData?.dob, "DD/MM/YYYY") : "",
    phone_no:
      editRelData !== undefined
        ? editRelData?.countryCode + editRelData?.phone
        : "+1 ",
    address: editRelData !== undefined ? editRelData?.address : "",
    city: editRelData !== undefined ? editRelData?.city : "",
    province: editRelData !== undefined ? editRelData?.province : "",
    postalCode: editRelData !== undefined ? editRelData?.postalCode : "",
  });

  useEffect(() => {
    if (editRelData !== undefined) {
      setInitData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRelData]);

  const setInitData = () => {
    console.log("editRelData", editRelData);
    setInitialData({
      firstName: editRelData?.firstName,
      lastName: editRelData?.lastName,
      dob: moment(editRelData?.dob, "DD/MM/YYYY"),
      phone_no: editRelData?.countryCode + editRelData?.phone,
      address: editRelData?.address,
      city: editRelData?.city,
      province: editRelData?.province,
      postalCode: editRelData?.postalCode,
    });
    setProfilePic(editRelData?.image);
    setGeocode(editRelData?.location);
    setValue(editRelData?.address);
    setCountryCode(editRelData?.countryCode);
    setMobile(editRelData?.mobile);
    handleFormChange();
  };

  console.log("initialData", editRelData);

  const getDetails = (place_id) => {
    SetShowList(false);
    placesService?.getDetails(
      {
        placeId: place_id,
      },
      (placeDetails) => {
        console.log(
          "placeDetails",
          placeDetails,
          placeDetails.geometry.location.lat(),
          placeDetails.geometry.location.lng()
        );
        const address = getAddressObject(placeDetails?.address_components);
        setValue(placeDetails?.name);
        form.setFieldValue("address", placeDetails?.name);
        form.setFieldValue("city", address?.city);
        form.setFieldValue("province", address?.region);
        form.setFieldValue("postalCode", address?.postal_code);
        form.validateFields(["postalCode"]);
        const location = {
          lat: placeDetails.geometry.location.lat(),
          lon: placeDetails.geometry.location.lng(),
        };
        setGeocode(location);
        handleFormChange();
      }
    );
  };

  const onFinish = (values) => {
    console.log("Success:", values);

    const data =
      profilePic != ""
        ? {
            firstName: values.firstName,
            lastName: values.lastName,
            dob: moment(values.dob).format("DD/MM/YYYY"),
            address: values.address,
            city: values.city,
            province: values.province,
            postalCode: values.postalCode,
            image: profilePic,
            phone: mobile,
            countryCode: contryCode,
            location: geocode,
          }
        : {
            firstName: values.firstName,
            lastName: values.lastName,
            dob: moment(values.dob).format("DD/MM/YYYY"),
            address: values.address,
            city: values.city,
            province: values.province,
            postalCode: values.postalCode,
            phone: mobile,
            countryCode: contryCode,
            location: geocode,
          };
    console.log("data ~~ ", data);
    save(data);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormChange = (changedFields) => {
    if (
      changedFields?.length > 0 &&
      changedFields[0]?.name[0]?.includes("postalCode")
    ) {
      let val = changedFields[0]?.value;
      val = val.replace(/[^\w\s]/g, "");
      if (val[3] && val[3] != " ") {
        val = val?.replace(/(.{3})/, "$1 ");
      }
      form.setFieldValue("postalCode", val.toUpperCase());
    }
    if (
      changedFields?.length > 0 &&
      (changedFields[0]?.name[0]?.includes("firstName") ||
        changedFields[0]?.name[0]?.includes("lastName"))
    ) {
      const name = changedFields[0]?.name[0];
      let val = changedFields[0]?.value;
      val = val.charAt(0).toUpperCase() + val.slice(1);
      form.setFieldValue(name, val);
    }
    const valuesObj = form.getFieldsValue();
    delete valuesObj.apartmentNumber;
    delete valuesObj.phone_no;
    const areTruthy = Object.values(valuesObj).every((value) => value);

    const hasErrors =
      !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      setLoadingProfile(true);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("path", "test");
      console.log("formData", formData);
      if (accessToken !== "") {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user/upload-image`,
            formData,
            {
              headers: {
                accessToken: accessToken,
                Authorization:
                  "Basic " +
                  btoa(
                    process.env.REACT_APP_USERNAME +
                      ":" +
                      process.env.REACT_APP_PASSWORD
                  ),
              },
            }
          )
          .then((response) => {
            console.log("response", response);
            // console.log("response", response)
            if (response.data.status === 200) {
              setProfilePic(response.data.output.imageUrl1.location);
              setLoadingProfile(false);
            } else if (response.data.status >= 400) {
              setLoadingProfile(false);
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: response?.data?.error?.message,
                  alertOpen: true,
                })
              );
            } else {
              setLoadingProfile(false);
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: "something went wrong! please try again.",
                  alertOpen: true,
                })
              );
            }
          })
          .catch((err) => {
            setLoadingProfile(false);
            if (err?.response.data.status >= 400) {
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: err?.response?.data?.error?.message,
                  alertOpen: true,
                })
              );
              if (err.response.data.status === 401) {
                dispatch(Logout());
                localStorage.clear();
                navigate("/");
                window.location.reload();
              }
            } else {
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: "something went wrong! please try again.",
                  alertOpen: true,
                })
              );
            }
          });
      }
    }
  };
  return (
    <div className="px-3 px-md-0">
      <h1 className="welcome-header fw-bolder mt-4" id="name">
        Care Recipient Profile
      </h1>
      <div className="px-md-5 text-center position-relative">
        <Spin spinning={loadingProfile}>
          <div className="user-circle mx-auto">
            {profilePic === "" ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "scale-down",
                }}
                src="/images/icons/Profile.png"
                alt="dummy profile icon"
              />
            ) : (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "scale-down",
                  borderRadius: "50%",
                }}
                src={profilePic}
                alt="dummy profile icon"
              />
            )}
            <label
              type="file"
              className="upload-file pointer"
              style={{ position: "absolute", bottom: "0px", right: "0px" }}
            >
              <input
                for="file"
                className="btn-3"
                onChange={handleImageUpload}
                accept="image/*"
                type="file"
              />
            </label>
          </div>
        </Spin>
      </div>
      <div className="col-12 text-start form-row form-row-div">
        <Form
          name="signup-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          // validateTrigger='onSubmit'
          onFieldsChange={handleFormChange}
          form={form}
          requiredMark={false}
          initialValues={initialData}
          className="mb-0 p-md-4 text-start"
        >
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name="firstName"
                type="text"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name",
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z ]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  },
                ]}
              >
                <Input size="large" placeholder="First Name *" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="lastName"
                type="text"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter your last name",
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z ]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  },
                ]}
              >
                <Input size="large" placeholder="Last Name *" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="dob"
                type="text"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter your date of birth!",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  clearIcon={null}
                  suffixIcon={
                    <img src="/images/icons/calendar.svg" alt="calendar" />
                  }
                  placeholder="Date of Birth *"
                  format={"DD/MM/YYYY"}
                  disabledDate={(current) => {
                    return current && current > moment().subtract(216, "month");
                  }}
                  defaultPickerValue={
                    form.getFieldValue("dob")
                      ? moment(form.getFieldValue("dob"))
                      : moment().set({ year: 2000 })
                  }
                  onChange={(d) => form.setFieldValue("dob", d)}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="phone_no"
                type="text"
                className="mb-3 phone-number-details"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please enter your phone number!",
                  // },
                  {
                    min: contryCode?.length + 10,
                    message: "Minimum 10 digits required.",
                  },
                ]}
              >
                <PhoneInput
                  inputExtraProps={{
                    placeholder: "Enter phone number",
                    searchPlaceholder: "Enter phone number",
                  }}
                  placeholder="Enter phone number *"
                  searchPlaceholder="Enter phone number"
                  country={
                    editRelData?.countryCode === undefined
                      ? "ca"
                      : editRelData?.countryCode
                  }
                  value={
                    (editRelData?.countryCode === undefined
                      ? ""
                      : editRelData?.countryCode) + editRelData?.phone
                  }
                  enableAreaCodes={false}
                  copyNumbersOnly={false}
                  inputClass="ant-input ant-input-lg"
                  containerClass="ant-input-affix-wrapper"
                  dropdownClass="ant-select ant-select-single"
                  containerStyle={{ padding: "2px 4px" }}
                  buttonStyle={{ background: "none", border: "none" }}
                  inputStyle={{ width: "100%", paddingLeft: "60px" }}
                  onChange={(value, country, e, formattedValue) => {
                    form.setFieldValue("countryCode", country?.dialCode);
                    form.setFieldValue(
                      "phone",
                      value.slice(country?.dialCode?.length)
                    );
                    setCountryCode(country?.dialCode);
                    setMobile(value.slice(country?.dialCode?.length));
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="address"
                type="text"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter your address!",
                  },
                ]}
              >
                <Input
                  size="large"
                  style={{ color: "black" }}
                  value={value}
                  onChange={(evt) => {
                    getPlacePredictions({ input: evt.target.value });
                    SetShowList(true);
                    setValue(evt.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event?.keyCode === 8 || event?.keyCode === 46) {
                      form.setFieldValue("city", "");
                      form.setFieldValue("province", "");
                      form.setFieldValue("postalCode", "");
                      handleFormChange();
                    }
                  }}
                  loading={isPlacePredictionsLoading}
                  placeholder="Address *"
                />
                {!isPlacePredictionsLoading && value !== "" && showList && (
                  <div className="google-autosuggestion-list">
                    <>
                      <List
                        dataSource={placePredictions}
                        renderItem={(item) => (
                          <List.Item onClick={() => getDetails(item?.place_id)}>
                            {/* {console.log("item", item, placePredictions)} */}
                            <List.Item.Meta title={item.description} />
                          </List.Item>
                        )}
                      />
                    </>
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name="apartmentNumber" type="text" className="mb-3">
                <Input size="large" placeholder="Unit/Apartment Number" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="city"
                type="text"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter your city!",
                  },
                ]}
              >
                <Input disabled size="large" placeholder="City *" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="province"
                type="text"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Province!",
                  },
                ]}
              >
                <Input disabled size="large" placeholder="Province *" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="postalCode"
                type="text"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Postal Code!",
                  },
                  {
                    max: 7,
                    message: "Maximum 7 digits can be entered",
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z0-9 ]*$/),
                    // message: "Please enter valid postal code",
                  },
                ]}
              >
                <Input size="large" placeholder="Postal Code *" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className="text-center mb-2">
            <Button
              className="btn-black auth-action-btn mt-3 mx-auto"
              shape="round"
              type="primary"
              size="large"
              htmlType="submit"
              disabled={disabledSave}
            >
              Next{" "}
              <img
                className="ms-2"
                src="/images/icons/right.svg"
                alt="right icon"
              />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.loginData.user.accessToken,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRelativeProfileDetails);
