import React, { useState, useEffect } from "react";
import { Form, Input, Button, Col, Row, Modal, Typography, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import axios from "axios";
import "./bank-details.less";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/alert/alert.action";
import StripeInformation from "./stripeInformation";
import { Logout } from "../../../../redux/auth/auth.action";
import { BankingInfoModal } from "../../settings/bank-details-update/BankingInformation";
function BankDetailsComp({ accessToken }) {
  const { Title, Text } = Typography;

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [showTnc, setShowTnc] = useState(false);
  const [tncChecked, setTncChecked] = useState(false);
  const [ipData, setIP] = useState();
  const [firstModal, setFirstModal] = useState(false);
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("form", form);
    console.log("elements", elements);
  }, [form, elements]);

  const handleFormChange = () => {
    const areTruthy = Object.values(form.getFieldsValue()).every(
      (value) => value
    );

    const hasErrors =
      !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    console.log("hasErrors", hasErrors);
    setDisabledSave(hasErrors);
  };

  const onFinish = (values) => {
    console.log("values", values);
    generateBankToken();
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setTncChecked(e.target.checked);
  };

  const generateBankToken = async (event) => {
    // event.preventDefault();
    setLoading(true);
    if (elements == null) {
      return;
    }
    //  setShowTnc(true);
    try {
      const token = await stripe?.createToken("bank_account", {
        bank_account: {
          country: "CA",
          currency: "cad",
          bank_name: form.getFieldValue("bankName"),
          account_holder_type: "individual",
          routing_number:
            String(form.getFieldValue("transitNumber")) +
            String(form.getFieldValue("institutionNumber")),
          account_number: form.getFieldValue("accountNumber"),
          // account_holder_name: "Jenny Rosen",
          // account_holder_type: "individual",
          // routing_number: "110000000",
          // account_number: "000123456789",
        },
      });
      if (token.error) {
        setShowTnc(false);
        setLoading(false);
        dispatch(
          setAlert({
            alertType: "error",
            alertMsg: token.error.message,
            alertOpen: true,
          })
        );
      }
      console.log("token", token);
      console.log("form.getFieldsValue()", form.getFieldsValue());
      console.log("stripe", stripe);
      const addBankPayload = {
        token: token?.token?.id,
        // bankName: "HDFC",
        // institutionNumber: "007",
        // transitNumber: "11000-000",
        // accountNumber: "000123456789",
        ...form.getFieldsValue(),
      };
      if (accessToken !== "") {
        const addBank = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/add-bank-account`,
          addBankPayload,
          {
            headers: {
              accessToken: accessToken,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
              // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzdmNmE3Njg5NzY5ZDFhY2NlYjhmZWMiLCJ1c2VyVHlwZSI6MCwiaWF0IjoxNjY5Mjk1MDg3fQ.HmthpWH475OcT11_ibikzGb7W1ltrIOVY6IR4Hs9dv4",
            },
          }
        );
        console.log(addBank.data, "addbank data");
        if (addBank.data.status === 200) {
          setLoading(false);
          dispatch(
            setAlert({
              alertType: "success",
              alertMsg: " Data added successfully",
              alertOpen: true,
            })
          );
          setShowTnc(true);
        }
      }
    } catch (err) {
      setLoading(false);
      if (err?.response.data.status >= 400) {
        dispatch(
          setAlert({
            alertType: "error",
            alertMsg: err?.response?.data?.error?.message,
            alertOpen: true,
          })
        );
        if (err.response.data.status === 401) {
          dispatch(Logout());
          localStorage.clear();
          navigate("/");
          window.location.reload();
        }
      }
    }
  };

  const handleCancel = () => {
    setShowTnc(false);
  };

  const sendStripeTnCDetails = async () => {
    try {
      setLoadingStripe(true);
      const res = await axios.get("https://ipv4.icanhazip.com/");
      console.log(res?.data, "ipv4dsfs");
      setIP(res?.data);
      const submitRes = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/agree-stripe`,
        {
          ip: res?.data.substring(0, res?.data.length - 2),
        },
        {
          headers: {
            accessToken: accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
            // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzdmNmE3Njg5NzY5ZDFhY2NlYjhmZWMiLCJ1c2VyVHlwZSI6MCwiaWF0IjoxNjY5Mjk1MDg3fQ.HmthpWH475OcT11_ibikzGb7W1ltrIOVY6IR4Hs9dv4",
          },
        }
      );
      setLoadingStripe(false);
      console.log(submitRes.data);
    } catch (err) {
      setLoadingStripe(false);
      if (err?.response?.data?.status >= 400) {
        dispatch(
          setAlert({
            alertType: "error",
            alertMsg: err?.response?.data?.error?.message,
            alertOpen: true,
          })
        );
        if (err?.response?.data?.status === 401) {
          dispatch(Logout());
          localStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        dispatch(
          setAlert({
            alertType: "error",
            alertMsg: "something went wrong! please try again.",
            alertOpen: true,
          })
        );
      }
    }
    navigate("/caregiver/stripe-verify");
  };

  const handleInfoModal = () => {
    setInfoModalVisible(true);
  };

  const validation = (rule, value, callback) => {
    if (value) {
      return callback();
    }
    return callback("This field is required");
  };

  return (
    <div className="px-3 mx-auto" style={{ maxWidth: "750px" }}>
      {/* <div className="text-end color-secondary skip-btn-mob fs-5 pe-4 pt-2 fw-bolder">
        <span
          className="pointer"
          onClick={() => {
            // navigate("/caregiver/add-availability");
            setShowTnc(true);
          }}
        >
          Skip
        </span>
      </div> */}

      <h1 className="color-primary fw-bolder mt-2 banking-title">
        Enter Your Banking Details
        {/* <img
          alt=""
        src="/images/icons/info-circle.svg"
        width={20}
        style={{margin: '0 0 5px 10px'}}
        // className="info-icon-tip"
        onClick={handleInfoModal}
      /> */}
      </h1>
      <h4 style={{ textAlign: "center" }} className="color-secondary mobile-h4">
        Stripe requires your banking details to direct deposit your earnings on
        a weekly basis.
      </h4>
      <div className="text-start bank-detail-wrapper">
        <Spin spinning={loading}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            // validateTrigger='onSubmit'
            onFieldsChange={handleFormChange}
          >
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={"bankName"}
                  rules={[
                    { required: true, message: "Bank Name is required" },
                    {
                      pattern: new RegExp(/^[a-zA-Z\s]*$/),
                      message: "Numbers and Special Characters Not Allowed",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Bank Name *"
                    type={"string"}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={"transitNumber"}
                  rules={[
                    { required: true, message: "Transit Number is required" },
                    { min: 5, message: "minimum 5 digits required" },
                    { max: 5 },
                  ]}
                >
                  <Input
                    size="large"
                    maxLength={5}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Transit Number *"
                    //  style={{ width: "50%" }}
                    className="bankinput"
                  ></Input>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={"institutionNumber"}
                  rules={[
                    {
                      required: true,
                      message: "Institution Number is required",
                    },
                    { min: 3, message: "minimum 3 digits required" },
                  ]}
                >
                  <Input
                    size="large"
                    maxLength={3}
                    placeholder="Institution Number *"
                    // style={{ width: "50%" }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className="bankinput"
                  ></Input>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={"accountNumber"}
                  rules={[
                    { required: true, message: "Account Number is required" },
                    // {
                    //   // max: 12,
                    //   max: 13,
                    //   message: "Value should be less than 16 character",
                    // },
                  ]}
                >
                  <Input
                    size="large"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    minLength={7}
                    maxLength={12}
                    placeholder="Account Number *"
                    //  style={{ width: "50%" }}
                    className="bankinput"
                  ></Input>
                </Form.Item>
              </Col>

              <Col xs={24} lg={24}>
                <Form.Item
                  className="bank-acknowledge"
                  name={"bankMandatoryCheck"}
                  rules={[{ validator: validation }]}
                  valuePropName="checked"
                  requiredMark={false}
                  label="When you render services, taxes are not automatically deducted from the earnings you accrue over the course of the year. It is your responsibility to ensure compliance with all tax obligations imposed by your jurisdiction."
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="text-center mb-2">
              <Button
                className="btn-black auth-action-btn mt-3 mb-5 mx-auto"
                shape="round"
                type="primary"
                size="large"
                // htmlType="submit"
                onClick={() => {
                  generateBankToken();
                }}
                disabled={!stripe || disabledSave}
              >
                Next{" "}
                <img
                  className="ms-2"
                  src="/images/icons/right.svg"
                  alt="right icon"
                />
              </Button>
            </Form.Item>
          </Form>
        </Spin>
        <Col xs={24} lg={10} sm={24}>
          <Modal
            open={showTnc}
            onCancel={handleCancel}
            closeIcon={<CloseCircleOutlined color="#000" />}
            footer={null}
            //width={"995px"}
            //height={"563px"}
            // width={"750px"}
            // height={"500px"}
            // height={"500px"}
            centered
            className="stripemodal"
            style={{ borderRadius: "20px", padding: "24px 24px 0px 24px" }}
          >
            <Spin spinning={loadingStripe}>
              {/* <div style={{width:"30px",float:"right"}}>
        <CloseCircleOutlined style={{float:"right",color:"black",fontSize:"25px"}} onClick={()=>{setShowTnc(false)}}/>
        </div> */}
              <Title className="mt-2 text-center ModalTitle" level={4}>
                Agree to Stripe Consent
              </Title>
              <div className="mt-4 mb-3">
                <Text
                  className="TextLevelSmallLabel"
                  style={{
                    fontSize: "16px",
                    textAlign: "justify",
                  }}
                >
                  <StripeInformation />
                </Text>
              </div>
              <Checkbox
                onChange={onChange}
                checked={tncChecked}
                style={{
                  "--background-color": "black",
                  "--border-color": "black",
                  marginTop: "30px",
                }}
              >
                Click to Agree
              </Checkbox>
              {/* <Form.Item className="text-center mb-2"> */}
              <Button
                disabled={!tncChecked}
                className="btn-black auth-action-btn auth-action-ban-details mt-3 mb-5 mx-auto"
                shape="round"
                type="primary"
                size="small"
                style={{ top: "-15px" }}
                // htmlType="submit"
                onClick={() => {
                  sendStripeTnCDetails();
                }}
              >
                Submit
                <img
                  className="ms-2"
                  src="/images/icons/right.svg"
                  alt="right icon"
                />
              </Button>
              {/* </Form.Item> */}
            </Spin>
          </Modal>
        </Col>
      </div>
      {/* <BankingInfoModal infoModalVisible={infoModalVisible} handleInfoModalClose={() => setInfoModalVisible(false)} /> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state?.loginData?.user?.accessToken,
  userDetails: state?.loginData?.user?.userDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsComp);
